import React from 'react';
import PropTypes from 'prop-types';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';

const EligibleState = ({ isLoading, onDismiss, onAcceptQuote }) => {
  return (
    <>
      <RestrictedButton
        onClick={onDismiss}
        dataTestId="BoilerCoverContinue"
        variant="outlined"
        label="Skip"
      />
      <RestrictedButton
        variant="contained"
        onClick={onAcceptQuote}
        disabled={isLoading}
        dataTestId="BoilerCoverAcceptQuote"
        label="Add cover"
      />
    </>
  );
};

EligibleState.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onAcceptQuote: PropTypes.func.isRequired,
};

export default EligibleState;
