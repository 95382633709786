import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import {
  TECHNOLOGY_LLU,
  TECHNOLOGY_LLX,
  TECHNOLOGY_FTTC,
  TECHNOLOGY_FTTP,
  TECHNOLOGY_SOGEA,
  TECHNOLOGY_ALL,
  BENEFIT_CONTRACT,
  BENEFIT_LANDLINE,
  BENEFIT_LANDLINE_GENERIC,
  BENEFIT_CONTRACT_GENERIC,
  BENEFIT_NO_PRICE_RISES,
} from 'redux/modules/Broadband/constants';
import useStyles from './styles';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';

const benefitsFTTC = [
  BENEFIT_CONTRACT,
  BENEFIT_LANDLINE,
  BENEFIT_NO_PRICE_RISES,
];
const benefitsLLUX = [BENEFIT_LANDLINE, BENEFIT_NO_PRICE_RISES];
const benefitsAll = [
  BENEFIT_CONTRACT_GENERIC,
  BENEFIT_LANDLINE_GENERIC,
  BENEFIT_NO_PRICE_RISES,
];
const benefitsFibre = [BENEFIT_CONTRACT, BENEFIT_NO_PRICE_RISES];
const tariffBenefitMap = {
  [TECHNOLOGY_LLU]: benefitsLLUX,
  [TECHNOLOGY_LLX]: benefitsLLUX,
  [TECHNOLOGY_FTTC]: benefitsFTTC,
  [TECHNOLOGY_FTTP]: benefitsFibre,
  [TECHNOLOGY_SOGEA]: benefitsFibre,
  [TECHNOLOGY_ALL]: benefitsAll,
};

const Benefits = ({ technology, showTariffTable }) => {
  const classes = useStyles();
  //for a single tab view with mixed tariffs keep the generic version of benefits
  //18-month contract applies only for non Standard tariffs
  const benefits = tariffBenefitMap[technology];
  return (
    <div
      className={classNames(
        classes.benefitList,
        showTariffTable && classes.tariffTable
      )}
    >
      {benefits.map((benefit) => (
        <div className={classes.benefitItem} key={benefit}>
          <FlagPointIcon className={classes.benefitIcon} />
          <Typography>{benefit}</Typography>
        </div>
      ))}
    </div>
  );
};

Benefits.propTypes = {
  technology: PropTypes.oneOf([
    TECHNOLOGY_LLU,
    TECHNOLOGY_FTTC,
    TECHNOLOGY_FTTP,
    TECHNOLOGY_SOGEA,
    TECHNOLOGY_ALL,
  ]).isRequired,
  showTariffTable: PropTypes.bool,
};
Benefits.displayName = 'Benefits';

export default React.memo(Benefits);
