import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const style = (theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 185,
    },
  },
  cardButton: {
    width: '100%',
    borderStyle: 'dashed',
    borderColor: theme.palette.text.primary,
    borderWidth: 1,
    height: 120,

    [theme.breakpoints.up('sm')]: {
      height: 212,
    },
  },
  cardButtonLoading: {
    opacity: 1,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      padding: theme.spacing(2, 1, 2),
    },
  },
  cardButtonDisabled: {
    background: 'transparent',
  },
  cardButtonChecked: {
    borderStyle: 'solid',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: 'auto',
    },
  },
  headerUnchecked: {
    opacity: 0.5,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  serviceIcon: {
    display: 'inline',
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: 48,
      height: 48,
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  serviceElectricity: {
    color: theme.palette.services.electricity,
  },
  serviceBroadband: {
    color: theme.palette.services.broadband,
  },
  serviceMobile: {
    color: theme.palette.services.mobile,
  },
  serviceInsurance: {
    color: theme.palette.services.insurance,
  },
  title: {
    display: 'block',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24,
  },
  separator: {
    display: 'none',
    width: 50,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderStyle: 'solid',
    borderColor: fade(theme.palette.text.primary, 0.4),
    borderBottom: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  description: {
    marginLeft: theme.spacing(4),
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      textAlign: 'center',
    },
  },
  descriptionText: {
    fontSize: 14,
  },
  selectedIcon: {
    position: 'absolute',
    top: -10,
    right: -10,
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(3),
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  lockedIcon: {
    color: theme.palette.alert.main,
    [theme.breakpoints.up('sm')]: {
      opacity: 0.5,
    },
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: `${theme.spacing(0.5)}px`,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    lineHeight: '1.2',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
      gap: 0,
      fontSize: 16,
    },
  },
  priceValues: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  price: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  discountPrice: {
    color: theme.palette.success.main,
  },
  priceUnselected: {
    opacity: 0.5,
  },
  asterisk: {
    fontSize: 16,
    verticalAlign: 'top',
    lineHeight: 1.6,
  },
});

export default makeStyles(style);
