import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'classnames';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { ApplicationApi } from 'redux/modules/App/api';
import useOptlyEvent from 'app/lib/analytics/Optimizely/useOptlyEvent';
import { events as optlyEvents } from 'app/lib/analytics/Optimizely/features';

import useStyles from './styles';

const AcceptBoilerCoverDialog = ({ open, setOpen, onAccept }) => {
  const classes = useStyles();
  const isRemote = useSelector(ApplicationApi.isRemoteSession);
  const optlyEvent = useOptlyEvent();
  const { variant: bhcBoilerService } = useFeature(
    features.HIDE_BHC_BOILER_SERVICE
  );
  const bhcBoilerServiceEnabled =
    bhcBoilerService === variations[features.HIDE_BHC_BOILER_SERVICE].OFF;

  const handleAccept = () => {
    optlyEvent(optlyEvents.ADD_BHC_TO_BUNDLE);
    onAccept();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: clsx({
          [classes.dialogRemote]: isRemote,
        }),
      }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <Typography variant="h3">Before you continue</Typography>
        <img
          className={classes.dialogTitleImg}
          alt="boiler"
          src="images/boilercover/boiler.svg"
        />
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <ul className={classes.heroDialogFeatures}>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body2">
              Your cover will start once we've reviewed and approved your
              application
            </Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body2">
              You don't have any cover that duplicates this cover (or will
              cancel the other cover once this policy starts)
            </Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body2">
              You own and occupy the home that this policy covers
            </Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body2">
              You have one boiler in your property and it is a single natural
              gas, oil or Liquid Petroleum Gas boiler, that is designed for
              domestic use with an output of no more than 70kWh
            </Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body2">
              Your excess for each claim is £60
            </Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body2">
              This policy is not Home Insurance
            </Typography>
          </li>
          {bhcBoilerServiceEnabled && (
            <li className={classes.heroDialogFeature}>
              <FlagPointIcon className={classes.heroDialogFeatureIcon} />
              <Typography variant="body2">
                You are entitled to a free annual boiler service
              </Typography>
            </li>
          )}
        </ul>
        <Typography variant="caption">
          I've reviewed the policy documents and the above statements, and
          confirm the information I've provided is accurate and the policy meets
          my needs.
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <RestrictedButton
          size="large"
          variant="contained"
          onClick={handleAccept}
          label="Agree and continue"
          dataTestId="BoilerCoverAgree"
        />
      </DialogActions>
    </Dialog>
  );
};

AcceptBoilerCoverDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default AcceptBoilerCoverDialog;
