import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  lock: {
    margin: theme.spacing(0, 1),
    color: fade(theme.palette.error.main, 0.5),
  },
  unlock: {
    margin: theme.spacing(0, 1),
    color: theme.palette.success.main,
  },
  lockedSaving: {
    color: theme.palette.text.disabled,
  },
  listItem: {
    display: 'flex',
    alignItems: 'start',

    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
});

export default makeStyles(styles);
