import { makeStyles } from '@material-ui/core';

export default makeStyles(({ breakpoints, spacing, palette }) => ({
  dialogTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeIcon: {
    color: palette.common.white,
  },
  dialogContent: {
    padding: spacing(2, 4, 4, 4),
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    height: 184,
  },
  heroDialogFeatures: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: spacing(2, 0, 0, 0),
    padding: 0,
  },
  heroDialogFeature: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: spacing(1),
    [breakpoints.up('sm')]: {
      marginBottom: spacing(0),
    },
  },
  heroDialogFeatureIcon: {
    color: palette.success.main,
    marginRight: spacing(2),
    [breakpoints.up('sm')]: {
      margin: spacing(1, 2, 1, 0),
    },
  },
}));
