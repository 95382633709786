import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      maxWidth: 200,
    },
  },
  image: {
    width: 100,
    height: 100,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  heading: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  savingsTip: {
    lineHeight: 1.2,
    fontSize: 14,
  },
});

const useStyles = makeStyles(styles);

const SavingsTip = ({ savingsTip }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        alt="savings tip"
        src={'images/bundlesPage/bundle_bot.svg'}
      />
      <div className={classes.textContainer}>
        <Typography className={classes.heading} variant="h4">
          Savings tip
        </Typography>
        <Typography className={classes.savingsTip}>{savingsTip}</Typography>
      </div>
    </div>
  );
};

SavingsTip.propTypes = {
  savingsTip: PropTypes.string.isRequired,
};

export default SavingsTip;
