import React from 'react';
import PropTypes from 'prop-types';

import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

import IneligibleState from './ineligibleState';
import EligibleState from './eligibleState';

const NavigationButtons = ({
  isEligible,
  isEligibilityLoading,
  hasGetQuoteError,
  setAcceptQuoteDialogOpen,
  updateBoilerCoverRequest,
  updateBoilerCover,
}) => {
  const trackOnceAddCover = useTrackOnce();
  const trackOnceSkip = useTrackOnce();
  const trackOnceRegisterInterest = useTrackOnce();
  const trackOnceSkipInterest = useTrackOnce();

  const onDismiss = () => {
    updateBoilerCover(false);
    updateBoilerCoverRequest();
  };

  if (hasGetQuoteError || !isEligible)
    return (
      <IneligibleState
        onDismiss={() => {
          onDismiss();
          trackOnceSkipInterest(
            EVENTS.INSURANCE_SKIPPED_BOILER_COVER_POLICY_FROM_INELIGIBILITY
          );
        }}
        onAccept={() => {
          trackOnceRegisterInterest(
            EVENTS.INSURANCE_EXPRESSED_INTEREST_IN_BOILER_COVER_POLICY
          );
          updateBoilerCover(true);
          updateBoilerCoverRequest();
        }}
      />
    );

  return (
    <EligibleState
      isLoading={isEligibilityLoading}
      onAcceptQuote={() => {
        trackOnceAddCover(EVENTS.INSURANCE_BOILER_COVER_POLICY_QUOTE_REVIEWED);
        setAcceptQuoteDialogOpen(true);
      }}
      onDismiss={() => {
        trackOnceSkip(EVENTS.INSURANCE_BOILER_COVER_POLICY_NO_THANKS_CLICKED);
        onDismiss();
      }}
    />
  );
};

NavigationButtons.propTypes = {
  isEligible: PropTypes.bool.isRequired,
  isEligibilityLoading: PropTypes.bool.isRequired,
  hasGetQuoteError: PropTypes.bool.isRequired,
  setAcceptQuoteDialogOpen: PropTypes.func.isRequired,
  updateBoilerCoverRequest: PropTypes.func.isRequired,
  updateBoilerCover: PropTypes.func.isRequired,
};

export default NavigationButtons;
