import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import CashbackCardDialog from './CashbackCardDialog';
import { EVENTS } from 'app/lib/analytics/constants';
import { connector } from 'app/redux/utils/connector';
import { STORE_NAME } from 'app/redux/modules/Bundles/constants';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    cursor: 'pointer',
  },
}));

const CashbackCardInfo = ({
  cashbackCardDialogOpen,
  setCashbackCardDialogOpen,
}) => {
  const analytics = useTracking();
  const classes = useStyles();

  const handleUWCashbackCardClick = () => {
    analytics.track(EVENTS.BUNDLES_CBC_LINK_CLICKED);
    setCashbackCardDialogOpen(true);
  };

  return (
    <>
      <CashbackCardDialog
        onClose={() => setCashbackCardDialogOpen(false)}
        open={cashbackCardDialogOpen}
      />
      <a className={classes.link} onClick={handleUWCashbackCardClick}>
        UW cashback card
      </a>
    </>
  );
};

CashbackCardInfo.propTypes = {
  cashbackCardDialogOpen: PropTypes.bool,
  setCashbackCardDialogOpen: PropTypes.func.isRequired,
};

export default connector(STORE_NAME)(CashbackCardInfo);
