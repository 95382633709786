import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  dialogRemote: {
    paddingBottom: `calc(80px - ${theme.spacing(3)}px)`,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
  dialogContent: {
    padding: theme.spacing(1, 2),
  },
  heroDialogFeatures: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  heroDialogFeature: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
    },
  },
  heroDialogFeatureIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1, 2, 1, 0),
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      height: 120,
      overflow: 'hidden',
      padding: theme.spacing(3, 1, 0, 3),
    },
  },
  closeIcon: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(-1),
  },
  dialogTitleImg: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'initial',
      height: 132,
      marginBottom: -32,
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
}));
