import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  Table,
  TableLegend,
  TableLegendCell,
  TableColumnContainer,
  TableColumn,
  TableCell,
} from 'modules/Shared/Table';
import {
  NEW_BP_PRICE,
  PRODUCT_ID_BILL_PROTECTOR,
  PRODUCT_ID_INCOME_PROTECTOR,
  productIdNameMap,
} from 'redux/modules/BillIncomeProtector/constants';
import { formatNumber } from 'redux/utils/formatter';
import useStyles from './styles';

const QuoteTableCell = ({ loading, fluid = false, children }) => {
  return (
    <TableCell fluid={fluid}>
      {loading && (
        <Skeleton
          animation="wave"
          variant="rect"
          width={140}
          style={{ display: 'inline-block' }}
        />
      )}
      {!loading && children}
    </TableCell>
  );
};

const QuoteTableSkeleton = () => (
  <>
    <TableColumn label={<Skeleton variant="rect" animation="wave" />}>
      <QuoteTableCell loading></QuoteTableCell>
      <QuoteTableCell loading></QuoteTableCell>
      <QuoteTableCell loading></QuoteTableCell>
      <QuoteTableCell loading></QuoteTableCell>
    </TableColumn>
    <TableColumn label={<Skeleton variant="rect" animation="wave" />}>
      <QuoteTableCell loading></QuoteTableCell>
      <QuoteTableCell loading></QuoteTableCell>
      <QuoteTableCell loading></QuoteTableCell>
      <QuoteTableCell loading></QuoteTableCell>
    </TableColumn>
  </>
);

QuoteTableCell.propTypes = {
  loading: PropTypes.bool.isRequired,
  fluid: PropTypes.bool,
  children: PropTypes.node,
};

const QuoteTable = ({
  quotes,
  selected,
  loading,
  onChange,
  billProtectorEligible,
  incomeProtectorEligible,
}) => {
  const classes = useStyles();

  return (
    <Table>
      <TableLegend>
        <TableLegendCell>Unemployment or inability to work</TableLegendCell>
        <TableLegendCell>Hospitalisation</TableLegendCell>
        <TableLegendCell>Accidental Death</TableLegendCell>
        <TableLegendCell>Price</TableLegendCell>
      </TableLegend>
      <TableColumnContainer>
        {loading && <QuoteTableSkeleton />}
        {!loading &&
          quotes.map((quote) => (
            <TableColumn
              label={productIdNameMap[quote.product.productId]}
              key={quote.id}
            >
              <QuoteTableCell>
                <Typography variant="h4" component="span" color="primary">
                  Up to £
                  {formatNumber(
                    parseInt(
                      quote.product.details.unemploymentBenefit.amount,
                      10
                    )
                  )}
                </Typography>
                <Typography variant="body2">
                  &times;{' '}
                  {quote.product.details.unemploymentBenefit.maxRecurrence}{' '}
                  months
                  <br />
                  {quote.product.productId === PRODUCT_ID_BILL_PROTECTOR &&
                    '(contribution to UW bills)'}
                  {quote.product.productId === PRODUCT_ID_INCOME_PROTECTOR &&
                    '(cash payment)'}
                </Typography>
              </QuoteTableCell>
              <QuoteTableCell>
                <Typography variant="h4" component="span" color="primary">
                  £
                  {formatNumber(
                    parseInt(
                      quote.product.details.hospitalisationCover.amount,
                      10
                    )
                  )}
                </Typography>
                <Typography variant="body2">
                  &times;{' '}
                  {quote.product.details.hospitalisationCover.maxRecurrence}{' '}
                  days
                </Typography>
              </QuoteTableCell>
              <QuoteTableCell>
                <Typography variant="h4" component="span" color="primary">
                  £
                  {formatNumber(
                    parseInt(
                      quote.product.details.accidentalDeathCover.amount,
                      10
                    )
                  )}
                </Typography>
                <Typography variant="body2">lump sum</Typography>
              </QuoteTableCell>
              <QuoteTableCell fluid>
                <Box
                  display="flex"
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: 1,
                  }}
                >
                  <Box display="flex" style={{ flexDirection: 'column' }}>
                    {quote.product.productId === PRODUCT_ID_BILL_PROTECTOR ? (
                      <>
                        <Typography
                          variant="h3"
                          component="span"
                          color="primary"
                        >
                          £{NEW_BP_PRICE}
                        </Typography>
                        <Typography variant="caption">a month</Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h3"
                          component="span"
                          color="primary"
                        >
                          £{quote.charges[0].amount.value}
                        </Typography>
                        <Typography variant="caption">a month</Typography>
                      </>
                    )}
                  </Box>
                  <Button
                    style={{ mt: 'auto' }}
                    data-test-id="BipQuoteSelect"
                    variant={
                      selected === quote.quoteId ? 'contained' : 'outlined'
                    }
                    onClick={() => onChange(quote.quoteId)}
                    className={classes.tableSelect}
                    disabled={
                      (quote.quoteId === PRODUCT_ID_BILL_PROTECTOR &&
                        !billProtectorEligible) ||
                      (quote.quoteId === PRODUCT_ID_INCOME_PROTECTOR &&
                        !incomeProtectorEligible)
                    }
                  >
                    Select
                  </Button>
                </Box>
              </QuoteTableCell>
            </TableColumn>
          ))}
      </TableColumnContainer>
    </Table>
  );
};

QuoteTable.propTypes = {
  billProtectorEligible: PropTypes.bool.isRequired,
  incomeProtectorEligible: PropTypes.bool.isRequired,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        productId: PropTypes.string.isRequired,
        productClass: PropTypes.string.isRequired,
        details: PropTypes.shape({
          accidentalDeathCover: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            frequency: PropTypes.number.isRequired,
            maxRecurrence: PropTypes.number.isRequired,
          }),
          hospitalisationCover: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            frequency: PropTypes.number.isRequired,
            maxRecurrence: PropTypes.number.isRequired,
          }),
          unemploymentBenefit: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            frequency: PropTypes.number.isRequired,
            maxRecurrence: PropTypes.number.isRequired,
          }),
        }),
      }),
      charges: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }).isRequired,
        })
      ),
      quoteId: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default QuoteTable;
