import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import trim from 'lodash/trim';
import delay from 'lodash/delay';
import { useHistory } from 'react-router-dom';
import { APPOINTMENT_PRESENTATION_URL } from 'config/externalLinks';
import { Snackbar } from '@material-ui/core';
import SkipAppointmentPresentationButton from '../Remote/RemoteSessionOverlay/Buttons/SkipAPButton';

import Iframe from './iframe';

const IFRAME_ID = 'appointmentPresentationIframe';
const RETRY_LIMIT = 12;

const dispatchEvent = function(event) {
  // propagate the iframe event to main window, makes remote cursors work
  const newEvent = new event.constructor(event.type, event);
  window.document.dispatchEvent(newEvent);
};

const onSlideChange = (callback, swiper) => {
  swiper && callback(swiper.realIndex);
};

// cms appointment presentation uses the swiperjs lib
// https://swiperjs.com
const ApplicationPresentation = ({
  page,
  isRemote,
  isDriver,
  handlePresentationPageChange,
}) => {
  const history = useHistory();
  const [iframeDocument, setIframeDocument] = useState(null);
  const [iframeSwiper, setIframeSwiper] = useState(null);
  const [retry, setRetry] = useState(-1);

  const nextDestination = isRemote ? '/' : '/organic';
  const onPresentationSkip = () => history.push(nextDestination);

  const onAPPageChangeTracked = handlePresentationPageChange;

  useEffect(() => {
    return () => {
      if (iframeDocument)
        iframeDocument.removeEventListener('mousemove', dispatchEvent);
      if (iframeSwiper)
        iframeSwiper.off('slideChange', () =>
          onSlideChange(onAPPageChangeTracked, iframeSwiper)
        );
    };
  }, []);

  const onLoad = () => {
    const iframe = window.document.getElementById(IFRAME_ID);
    const document = iframe ? iframe.contentDocument : null;
    const contentWindow = iframe ? iframe.contentWindow : null;

    // ignore the logs from the iframe after onLoad
    if (contentWindow && contentWindow.console) {
      contentWindow.console.log = function() {
        /* nop */
      };
      contentWindow.console.warn = function() {
        /* nop */
      };
      contentWindow.console.error = function() {
        /* nop */
      };
    }

    if (document) {
      const swiperContainer = document.getElementsByClassName(
        'swiper-container'
      );
      const swiper =
        swiperContainer && swiperContainer.length
          ? swiperContainer[0].swiper
          : null;
      if (swiper) {
        swiper.on('slideChange', () =>
          onSlideChange(onAPPageChangeTracked, swiper)
        );
        setIframeSwiper(swiper);
        setRetry(-1); // everything ok, disable retry
      } else setRetry(retry + 1);
      if (!iframeDocument) {
        document.addEventListener('mousemove', dispatchEvent);
      }
      setIframeDocument(document);

      // disable all links
      // navigation is done using divs, not `a` elements so we don't have to do anything
      const linkButtons = document.getElementsByTagName('a');
      forEach(linkButtons, (button) => {
        // allow vimeo buttons and skip last 3 AP links
        const innerText = trim(button.innerText);
        if (
          !includes(button.classList, 'uw-video__play-btn') &&
          innerText !== 'Earn as a Partner' &&
          innerText !== 'Save as a customer' &&
          innerText !== 'Or both' &&
          innerText !== 'Watch to find out more' &&
          innerText !== 'Your UW Partner shows you how'
        ) {
          // disable the button
          button.href = 'javascript:void(0);';
          button.classList.add(
            'presentation-page__navigation-button--disabled'
          );
          button.target = '';
          button.click = (e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          };
        }
      });

      // custom behaviour for the last 2 links in the AP.
      const iconLinks = document.getElementsByClassName('uw-link-with-icon');
      forEach(iconLinks, (link) => {
        const innerText = trim(link.innerText);
        if (innerText == 'Save as a customer' || innerText == 'Or both') {
          link.href = 'javascript:void(0);';
          link.onclick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            onPresentationSkip();
          };
          link.touchstart = (e) => {
            e.preventDefault();
            e.stopPropagation();
            onPresentationSkip();
          };
        }
      });
    } else setRetry(retry + 1);
  };

  if (isRemote && !isDriver && iframeSwiper && iframeSwiper.page !== page) {
    // scroll if remote appointment
    iframeSwiper.slideTo(page);
  }

  if (retry === RETRY_LIMIT) {
    setRetry(-1);
  } else if (retry !== -1 && retry < RETRY_LIMIT) {
    delay(() => onLoad(), 1000);
  }

  return (
    <>
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SkipAppointmentPresentationButton
          disabled={isRemote && !isDriver}
          onClick={onPresentationSkip}
        />
      </Snackbar>
      <Iframe
        id={IFRAME_ID}
        src={APPOINTMENT_PRESENTATION_URL}
        onLoad={() => onLoad()}
      />
    </>
  );
};

ApplicationPresentation.propTypes = {
  page: PropTypes.number,
  isDriver: PropTypes.bool,
  isRemote: PropTypes.bool,
  handlePresentationPageChange: PropTypes.func.isRequired,
};

export default ApplicationPresentation;
