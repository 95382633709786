import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';

import ContentDropdown from 'components/modules/Shared/ContentDropdown';
import {
  COVERED_BUTTON,
  NOT_COVERED_BUTTON,
} from 'redux/modules/BoilerCover/constants';

import ListCovered from './ListCovered';
import ListNotCovered from './ListNotCovered';

import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: spacing(3),
  },
  label: {
    fontWeight: 600,
  },
  contentDropdownRoot: {
    alignSelf: 'flex-start',
    marginLeft: spacing(-2),
  },
  btnContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(3),
  },
  checkCardBtnBase: {
    width: '100%',
  },
  checkCardRoot: {
    width: '49%',
  },
}));

const Covered = ({ open, selected, setOpen, setSelectedTab }) => {
  const classes = useStyles();
  const trackOnceDropdownClicked = useTrackOnce();

  return (
    <div className={classes.container}>
      <ContentDropdown
        fullWidth
        label={
          <Typography className={classes.label}>What's covered</Typography>
        }
        open={open}
        onOpen={() => {
          setOpen(!open);
          trackOnceDropdownClicked(
            EVENTS.INSURANCE_BOILER_COVER_DROPDOWN_CLICKED
          );
        }}
        classes={{ root: classes.contentDropdownRoot }}
      >
        <div className={classes.btnContainer}>
          <CheckCard
            checkCardClasses={{
              root: classes.checkCardRoot,
              buttonBase: classes.checkCardBtnBase,
            }}
            checked={selected === COVERED_BUTTON}
            name="whatsCovered"
            onChange={(e) => {
              setSelectedTab(e.target.value);
            }}
            value={COVERED_BUTTON}
            variant="radio"
          >
            <CardContent classes={{ root: classes.contentCard }}>
              <Typography color="primary" variant="h4">
                Things we cover
              </Typography>
            </CardContent>
          </CheckCard>
          <CheckCard
            checkCardClasses={{
              root: classes.checkCardRoot,
              buttonBase: classes.checkCardBtnBase,
            }}
            checked={selected === NOT_COVERED_BUTTON}
            name="whatsCovered"
            onChange={(e) => {
              setSelectedTab(e.target.value);
            }}
            value={NOT_COVERED_BUTTON}
            variant="radio"
          >
            <CardContent classes={{ root: classes.contentCard }}>
              <Typography color="primary" variant="h4">
                Things we don't cover
              </Typography>
            </CardContent>
          </CheckCard>
        </div>
        {selected === COVERED_BUTTON && <ListCovered />}
        {selected === NOT_COVERED_BUTTON && <ListNotCovered />}
      </ContentDropdown>
    </div>
  );
};

Covered.propTypes = {
  open: PropTypes.bool.isRequired,
  selected: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default Covered;
