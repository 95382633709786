import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import SectionCentered from 'modules/layout/SectionCentered';

import useStyles from './styles';

import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

const BoilerCoverQuote = ({ quote, setClickedDocument }) => {
  const classes = useStyles();
  const analytics = useTracking();

  const trackDocumentClicked = (name) => {
    analytics.track(EVENTS.INSURANCE_BOILER_COVER_QUOTE_DOCUMENT_OPENED, {
      document_title: name,
    });
  };

  return (
    <SectionCentered
      newLayout
      sectionCenteredClasses={{ container: classes.container }}
    >
      <div>
        <Typography color="primary" variant="h2">
          Your quote: £{quote.charges[0].amount.value}
          <Typography component="span"> / month</Typography>
        </Typography>
        <div className={classes.linkContainer}>
          {map(quote.product.details.documents, ({ name, url }) => (
            <div key={name} className={classes.linkWrapper}>
              <Link
                variant="h5"
                className={classes.link}
                underline="always"
                href={url ?? ''}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  trackDocumentClicked(name);
                  if (!url) {
                    setClickedDocument(name);
                    e.preventDefault();
                  }
                }}
              >
                {name}
              </Link>
              <IconButton
                color="secondary"
                aria-label="open"
                onClick={() => {
                  trackDocumentClicked(name);
                  if (url) {
                    window.open(url);
                  } else {
                    setClickedDocument(name);
                  }
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </div>
          ))}
        </div>
        <Typography variant="caption">
          We'll email you personalised documents when the policy starts
        </Typography>
      </div>
      <div className={classes.imageContainer}>
        <img alt="boiler" src="images/boilercover/boiler.svg" />
      </div>
    </SectionCentered>
  );
};

BoilerCoverQuote.propTypes = {
  quote: PropTypes.shape({
    charges: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
      })
    ),
    product: PropTypes.shape({
      details: PropTypes.shape({
        documents: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          })
        ).isRequired,
      }),
    }),
  }),
  setClickedDocument: PropTypes.func.isRequired,
};

export default BoilerCoverQuote;
