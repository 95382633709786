import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 4, 3, 4),
    [theme.breakpoints.up('md')]: {
      margin: ({ hasClose }) => theme.spacing(hasClose ? 2 : 12),
    },
  },
  loader: {
    color: theme.palette.line.primary,
    marginBottom: theme.spacing(6),
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.common.white,
  },
}));

const LoadingBoilerCoverDialog = ({ open, onClose, message }) => {
  const hasClose = !!onClose;

  const classes = useStyles({
    hasClose,
  });

  return (
    <Dialog open={open}>
      {hasClose && (
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <IconButton
            className={classes.closeIcon}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent classes={{ root: classes.dialogContent }}>
        <CircularProgress
          className={classes.loader}
          size={120}
          thickness={1.5}
        />
        <Typography variant="h3" color="primary">
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

LoadingBoilerCoverDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default LoadingBoilerCoverDialog;
