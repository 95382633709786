import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Typography } from '@material-ui/core';

import {
  GENERAL_TERMS_URL,
  HELP_URL,
  BROADBAND_SPEED_HELP_URL,
  BROADBAND_SPEED_CODE_OF_PRACTICE_URL,
  BROADBAND_TARIFF_INFO_URL,
} from 'config/externalLinks';
import ContentDropdown from 'components/modules/Shared/ContentDropdown';
import useStyles from './styles';

const MoreInfoDropdown = ({
  open,
  bottomSpacing,
  setBroadBandMoreInfo,
  displayPromoTerms,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.container,
        bottomSpacing && classes.bottomSpacing
      )}
    >
      <ContentDropdown
        label="More on broadband speeds and terms"
        fullWidth
        open={open}
        onOpen={() => setBroadBandMoreInfo(!open)}
        classes={{
          root: classes.detailsDropdownRoot,
          label: classes.detailsDropdownlabel,
        }}
      >
        <div className={classes.body}>
          <Typography variant="body1" paragraph>
            We work out your broadband speeds from the home phone number or
            address you'd like connected.
          </Typography>
          <Typography variant="body1" paragraph>
            The download and upload speeds available for your address are the
            estimated speeds between your wireless router and the internet.
            Actual speeds may be lower. These are based on what your broadband
            line can support at peak times (8pm – 10pm).
          </Typography>
          <Typography variant="body1" paragraph>
            Your 'Minimum download speed' on your line is the absolute minimum
            download speed that you can expect to receive on your broadband line
            at peak times.
          </Typography>
          <Typography variant="body1" paragraph>
            We calculate your estimated download speeds by looking at the speed
            of customers that have a line of similar length and characteristics
            to yours, and account for any congestion experienced at peak-times
            from the wireless router to the internet. This is referred to as the
            'throughput speed' to your wireless router.
          </Typography>
          <Typography variant="body1" paragraph>
            Where we can't to get any information about the telephone line or
            address, we'll quote 'up to' speeds.{' '}
            <Link
              href={BROADBAND_SPEED_HELP_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              Broadband speeds can be affected by a number of factors
            </Link>
            . However, for the best speed possible, we recommend using a cable
            to connect to your router. For more information visit our{' '}
            <Link
              href={HELP_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              Help and Support
            </Link>{' '}
            page. The speeds we have quoted are estimates and can be affected by
            a range of factors so you may find your actual speeds are lower. We
            will send you further information about your speeds and what you can
            do to improve them if you need to by email after you have completed
            your order.
          </Typography>
          <Typography className={classes.bold}>
            Your customer satisfaction is important to us
          </Typography>
          <Typography variant="body1" paragraph>
            If your Broadband speed drops below the quoted 'Minimum download
            speed' and we can't fix it within 30 calendar days of your raising
            the issue with us, you have the right to end the contract without
            paying the early termination fee. This is in accordance with Ofcom's{' '}
            <Link
              href={BROADBAND_SPEED_CODE_OF_PRACTICE_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              Voluntary Code of Practice on Broadband Speeds
            </Link>
            .
          </Typography>
          <Typography variant="body1" paragraph>
            We also offer a Standard Broadband with average speeds of 11Mb and
            at a cost of £38.49 a month – available as a 30 days rolling
            contract. If you would like to see whether our Standard Broadband is
            available to you, please call our sales team on{' '}
            <Link underline="always" href="tel:03337770777">
              0333 777 0777
            </Link>{' '}
            to check availability. See{' '}
            <Link
              href={GENERAL_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              our Terms
            </Link>{' '}
            and{' '}
            <Link
              href={BROADBAND_TARIFF_INFO_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              Tariff Guide
            </Link>{' '}
            for more details.
          </Typography>
          {displayPromoTerms && (
            <Typography variant="body1" paragraph>
              For full promotional terms and conditions please see{' '}
              <Link
                href={`${GENERAL_TERMS_URL}?#4zLPiBn0D5SIJFCr4udGi1`}
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
              >
                here
              </Link>
            </Typography>
          )}
        </div>
      </ContentDropdown>
    </div>
  );
};

MoreInfoDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  bottomSpacing: PropTypes.bool.isRequired,
  setBroadBandMoreInfo: PropTypes.func.isRequired,
  displayPromoTerms: PropTypes.bool.isRequired,
};

export default MoreInfoDropdown;
