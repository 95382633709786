import React from 'react';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import useStyles from './styles';
import ServiceCounter from 'modules/Bundles/Savings/ServiceCounter';
import { SavingsList } from 'modules/Bundles/Savings/SavingsList';
import { getHeadingText } from 'modules/Bundles/Savings/helpers';
import classNames from 'classnames';
import { saving } from 'app/redux/modules/Bundles/propTypes';

const Savings = ({
  serviceCount,
  selectedServices,
  savingsBenefits,
  allServicesSelected,
}) => {
  const classes = useStyles();
  const heading = getHeadingText(serviceCount, selectedServices);
  const {
    messages: { locked: lockedSavings, unlocked: unlockedSavings },
  } = savingsBenefits;

  return (
    <div className={classes.container}>
      <div
        className={classNames(
          classes.topBar,
          allServicesSelected && classes.confettiBar
        )}
      ></div>
      <Paper className={classes.paper}>
        <Typography className={classes.heading} variant="h4">
          {heading}
        </Typography>
        <ServiceCounter
          selectedServices={selectedServices}
          serviceCount={serviceCount}
        />
        <SavingsList
          unlockedSavings={unlockedSavings}
          lockedSavings={lockedSavings}
        />
      </Paper>
      {allServicesSelected && <div className={classes.confettiBar} />}
    </div>
  );
};

Savings.propTypes = {
  serviceCount: PropTypes.number.isRequired,
  selectedServices: PropTypes.number.isRequired,
  savingsBenefits: PropTypes.shape({
    messages: PropTypes.shape({
      locked: PropTypes.arrayOf(saving).isRequired,
      unlocked: PropTypes.arrayOf(saving).isRequired,
    }).isRequired,
  }).isRequired,
  allServicesSelected: PropTypes.bool.isRequired,
};
export default Savings;
