import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {
  PRIVACY_URL,
  BUNDLE_BENEFITS_TERMS_URL,
  GENERAL_TERMS_URL,
} from 'app/config/externalLinks';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@utilitywarehouse/partner-ui-icons';
import SectionCentered from 'modules/layout/SectionCentered';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    color: theme.palette.text.primary,
    padding: theme.spacing(0),
  },
  buttonText: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  chevron: {
    color: theme.palette.secondary.main,
  },
  section: {
    paddingBottom: theme.spacing(3.5),
  },
}));

const LegalInformation = () => {
  const classes = useStyles();
  const [showLegalBit, setShowLegalBit] = useState(false);
  const newMobileTariffsEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector('new.mobile.tariffs')
  );

  return (
    <SectionCentered
      sectionCenteredClasses={{
        root: classes.section,
      }}
    >
      <Button
        onClick={() => setShowLegalBit(!showLegalBit)}
        endIcon={showLegalBit ? <ChevronUpIcon /> : <ChevronDownIcon />}
        classes={{ root: classes.button, endIcon: classes.chevron }}
      >
        <span className={classes.buttonText}>Legal Bit</span>
      </Button>
      {showLegalBit && (
        <Typography variant="body2" component="div">
          <h4 className={classes.bold}>Energy discount</h4>
          <p>
            * £50 discount applies to customers who switch their energy plus 2
            or 3 other services (broadband, mobile and/or insurance - Bill
            Protector excluded. Boiler & Home Cover also excluded for tenants).
            Lower discounts will be applied to customers taking just energy, or
            energy plus one other service. Discounts only apply to customers on
            a variable energy tariff paying by Direct Debit with a credit meter
            and are calculated at a flat rate using the typical domestic
            consumption (as set by Ofgem) compared to the Government’s energy
            price cap level for default energy tariffs. Discount could be higher
            or lower depending on actual consumption. See terms for more
            details.
          </p>
          <h4 className={classes.bold}>
            £100 a year below the Government’s price cap
          </h4>
          <p>
            Available to eligible 4 Service Bundle customers (energy, broadband,
            mobile & insurance - excl. Bill Protector) with a credit meter. Dual
            fuel required, lower saving available with single fuel. £100 made up
            of (i) Double Gold variable tariff priced at £50 lower than the
            government's price cap and (ii) £50 annual bundle credit. Bill value
            will vary depending on energy consumption. Full details, eligibility
            and terms available at{' '}
            <Link
              underline="always"
              href={GENERAL_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              UW terms and conditions
            </Link>
            .
          </p>

          <h4 className={classes.bold}>Broadband & Mobile</h4>
          <p>
            The Mobile 'from' price is based on the price of our{' '}
            {newMobileTariffsEnabled ? 'Essential' : 'Value'} SIM only tariff.{' '}
            <Link
              underline="always"
              href={BUNDLE_BENEFITS_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </Link>{' '}
            apply.
          </p>

          <h4 className={classes.bold}>Insurance</h4>
          <p>
            Home insurance is included in the new bundle proposition and does
            count as a service required to unlock benefits. The Insurance 'from'
            price is based on the price of our Income Protector product.
          </p>
          <h4>£400 To Switch</h4>
          <p>
            **£400 to help you switch applies when you switch at least three of
            your services: energy, broadband, mobile and insurance (excluding
            Home Insurance) - and you are a new customer. £400 to help you
            switch - we’ll give you credit up to £400 towards any termination
            fees you have to pay your current providers. Additional requirements
            apply to customers who are tenants.{' '}
            <Link
              underline="always"
              href={BUNDLE_BENEFITS_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </Link>{' '}
            apply
          </p>

          <p>
            Please read our{' '}
            <Link
              underline="always"
              href={PRIVACY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Notice
            </Link>{' '}
            to find out more about how we collect, use and look after your
            personal information.
          </p>
        </Typography>
      )}
    </SectionCentered>
  );
};

export default LegalInformation;
