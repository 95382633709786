import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  root: {
    paddingBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  bodyText: {
    fontSize: 20,
    '& $bold': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  bold: {},
  tableSelect: {
    marginTop: theme.spacing(2),
  },
  contentDropdownButton: {
    marginLeft: theme.spacing(-2),
  },
  dialogRemote: {
    paddingBottom: 80,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 96,
  },
  closeIcon: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(-1),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(1, 2),
  },
  dialogContentInline: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogFeatures: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: theme.spacing(2, 0),
    padding: 0,
  },
  dialogFeature: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
    },
  },
  dialogFeatureIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1, 2, 1, 0),
    },
  },
  dialogSubTitle: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5),
  },
  buttonLoadingIndicator: {
    marginLeft: theme.spacing(1),
  },

  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  linkWrapper: {
    maxWidth: 400,
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paddingBottom: {
    paddingBottom: theme.spacing(5),
  },
  heroFeatures: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}px`,
    margin: 0,
    marginTop: `${theme.spacing(4)}px`,
    padding: theme.spacing(0, 0, 6, 0),
    listStyle: 'none',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  heroFeature: {
    flex: `0 0 calc(25% - ${theme.spacing(4)}px)`,
    display: 'flex',
    alignItems: 'center',
    columnGap: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      columnGap: 0,
    },
  },
  heroFeatureIcon: {
    color: theme.palette.success.main,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
      fontSize: 48,
    },
  },
});

export default makeStyles(style);
