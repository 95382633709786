import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  CardContent,
  CardActions,
  Button,
  makeStyles,
  Box,
} from '@material-ui/core';

import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';

import Card from 'components/modules/Shared/Card/Card';
import CardHeader from 'components/modules/Shared/Card/CardHeader';
import { getRouterName } from 'app/redux/modules/Broadband/helpers';

const useStyles = makeStyles((theme) => {
  const labelHeight = 25;
  const cardMarginBottom = theme.spacing(2);

  return {
    cardContainer: {
      minWidth: 300,
      [theme.breakpoints.down('md')]: { width: '100%' },
      height: 'auto',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: cardMarginBottom,
      height: `calc(100% - ${labelHeight}px - ${cardMarginBottom}px)`,
    },
    content: {
      flexGrow: 1,
    },
    cardSection: {
      padding: theme.spacing(1, 0),
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 24,
    },
    test: {
      border: 'solid',
    },
    cardIcon: {
      color: theme.palette.success.main,
      marginRight: theme.spacing(1),
    },
    aside: {
      fontWeight: 600,
    },
    select: {
      marginBottom: theme.spacing(1),
    },
    labelContainer: {
      display: 'flex',
      position: 'relative',
      height: labelHeight,
      marginBottom: -2,
      textAlign: 'center',
      justifyContent: 'right',
      marginRight: theme.spacing(2),
      zIndex: -1,
    },
    labelText: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      justifyContent: 'center',
      padding: theme.spacing(0.5, 1, 0, 1),
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      width: 'fit-content',
      lineHeight: 1.2,
    },
  };
});

const RouterCard = ({ router, selected, onSelect, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Box component="div" className={classes.labelContainer}>
        <Typography
          variant="body2"
          classes={{ root: classes.labelText }}
          hidden={!router.bestOnMarket}
        >
          Rated best on the market
        </Typography>
      </Box>
      <Card
        {...props}
        classes={{
          root: classes.card,
        }}
      >
        <CardHeader title={getRouterName(router)} />
        <CardContent className={classes.content}>
          <div className={classes.cardSection}>
            <Typography>{router.description}</Typography>
          </div>
          {router.aside && (
            <div className={classes.cardSection}>
              <FlagPointIcon className={classes.cardIcon} />
              <Typography classes={{ root: classes.aside }}>
                {router.aside}
              </Typography>
            </div>
          )}
        </CardContent>
        <CardActions>
          <Button
            variant={selected ? 'contained' : 'outlined'}
            fullWidth
            className={classes.select}
            size="large"
            onClick={() => onSelect(router.name)}
            data-test-id={`bb_router_${router.name.replace(/[^\w]/g, '_')}`}
          >
            {selected ? 'Selected' : 'Select'}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

RouterCard.propTypes = {
  onSelect: PropTypes.func.isRequired,
  router: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    aside: PropTypes.string,
    bestOnMarket: PropTypes.bool,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
};

export default React.memo(RouterCard);
