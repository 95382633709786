import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'classnames';
import {
  useMediaQuery,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { ApplicationApi } from 'redux/modules/App/api';

import useStyles from './styles';

const Feature = ({ children }) => {
  const classes = useStyles();
  return (
    <li className={classes.dialogFeature}>
      <FlagPointIcon className={classes.dialogFeatureIcon} />
      <Typography>{children}</Typography>
    </li>
  );
};

Feature.propTypes = {
  children: PropTypes.node.isRequired,
};

const AcceptDialog = ({
  open,
  setOpen,
  onAccept,
  loading,
  selectedProduct,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isRemote = useSelector(ApplicationApi.isRemoteSession);
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isIP = selectedProduct === 'Income Protector';

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      classes={{
        paperFullScreen: clsx({
          [classes.dialogRemote]: isRemote,
        }),
      }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <Typography variant="h3">Before you continue</Typography>
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Typography>
          We want to make sure this product is right for you. Please check the
          following eligibility criteria apply to everyone you intend to be
          named on your UW account:
        </Typography>
        <ul className={classes.dialogFeatures}>
          <Feature>
            You are over 18 and under {isIP ? 64 : 90} years of age
          </Feature>
          <Feature>You are a permanent resident of the UK</Feature>
          <Feature>
            You are working at least 16 hours a week in the UK on a contract
            that is permanent (or a minimum of 12 months)
          </Feature>
        </ul>
        <Typography
          variant="h4"
          component="h3"
          color="primary"
          className={classes.dialogSubTitle}
        >
          Disability cover only
        </Typography>
        <ul className={classes.dialogFeatures}>
          <Feature>
            You are not currently absent from work due to illness or injury,
            other than minor illness such as cold of flu
          </Feature>
        </ul>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <RestrictedButton
          size="large"
          variant="outlined"
          onClick={() => setOpen(false)}
          dataTestId="BipAcceptCancel"
          label="Skip"
        />
        <RestrictedButton
          size="large"
          variant="contained"
          onClick={onAccept}
          dataTestId="BipAcceptConfirm"
          label={
            <>
              Add cover
              {loading && (
                <CircularProgress
                  size={24}
                  thickness={5}
                  color="secondary"
                  className={classes.buttonLoadingIndicator}
                />
              )}
            </>
          }
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  );
};

AcceptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedProduct: PropTypes.string.isRequired,
};

export default AcceptDialog;
