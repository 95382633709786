import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import useStyles from './styles';

const ServiceCounter = ({ serviceCount, selectedServices, children }) => {
  const classes = useStyles();
  const progressValue = clamp((100 * selectedServices) / serviceCount, 0, 100);
  return (
    <div>
      <LinearProgress
        variant="determinate"
        value={progressValue}
        classes={{
          root: classes.progressRoot,
          bar: classes.progressBar,
        }}
      />
      <div className={classes.legend}>
        <Typography className={classes.legendLabel}>Services</Typography>
        {range(1, serviceCount + 1).map((services) => (
          <Typography key={services} className={classes.legendItem}>
            {services}
          </Typography>
        ))}
      </div>
      <Typography className={classes.maximumSavingsLabel}>Maximum</Typography>
      <Typography className={classes.maximumSavingsLabel}>savings</Typography>
      {children}
    </div>
  );
};

ServiceCounter.propTypes = {
  serviceCount: PropTypes.number.isRequired,
  selectedServices: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export default ServiceCounter;
