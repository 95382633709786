import { makeStyles } from '@material-ui/core';

export default makeStyles(({ breakpoints, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: spacing(4),
    marginBottom: spacing(4),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(4),
    marginBottom: spacing(2),
  },
  linkWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    marginRight: spacing(2),
  },
  imageContainer: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'initial',
      marginRight: spacing(3),
      marginTop: spacing(3),
    },
  },
}));
