import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: 'center',
    alignContent: 'center',
    margin: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  heading: {
    color: theme.palette.primary.main,
    margin: theme.spacing(2, 0),
    maxWidth: 450,
    display: 'inline-block',
  },
}));

const CashbackCardDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <img alt="cashback card" src="images/bundlesPage/cashback_card.svg" />
        <div>
          <Typography variant="h3" className={classes.heading}>
            Save even more with a UW cashback card
          </Typography>
        </div>
        <Typography variant="body1">
          Go shopping using your UW cashback card and earn up to 10% cashback,
          which comes directly off your bill.
          <br />
          <br />
          The card costs just £2 a month, and to get you started we’ll give you
          a free credit when you add four of our services.
          <br />
          <br />
          You’ll be able to add your cashback card later.
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button size="large" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CashbackCardDialog.defaultProps = {
  open: false,
};

CashbackCardDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default CashbackCardDialog;
