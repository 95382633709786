import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { AddIcon, RemoveIcon } from '@utilitywarehouse/partner-ui-icons';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(4.5, 0),
  },
  heading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.overrides.MuiTypography.h2.fontSize,
    },
  },
  subHeading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.overrides.MuiTypography.h3.fontSize,
    },
  },
  text: {
    margin: theme.spacing(3, 0),
  },
  list: {
    listStyle: 'none',
    margin: theme.spacing(3, 0),
    padding: 0,
    '& > li': {
      ...theme.typography.body1,
      backgroundImage: `url('images/broadband/v2/list-check-mark.svg')`,
      backgroundRepeat: 'no-repeat',
      margin: theme.spacing(2, 0),
      paddingLeft: theme.spacing(4.5),
    },
  },
  buttonRoot: {
    padding: theme.spacing(0.25, 0),
    borderRadius: 0,
    margin: theme.spacing(0, 2),
  },
  buttonText: {
    ...theme.typography.h5,
    ...theme.overrides.MuiTypography.h5,
    color: theme.palette.text.primary,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.overrides.MuiTypography.h4.fontSize,
    },
  },
  deviceItem: {
    margin: theme.spacing(2),
  },
  deviceItemOff: {
    opacity: 0.25,
  },
  selectContainer: {
    display: 'flex',
    marginTop: theme.spacing(-2),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
  },
  selectControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
    marginRight: theme.spacing(4.5),
  },
  selectButtonRoot: {
    color: theme.palette.text.primary,
    border: theme.overrides.MuiButton.outlinedSecondary.border,
    padding: theme.spacing(1),
    '&$selectButtonDisabled': {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.action.disabled,
    },
  },
  selectButtonDisabled: {
    /* local rule reference */
  },
  selectDevicesList: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: theme.spacing(2, -2, 0, -2),
    },
  },
  selectCounter: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
}));

const EeroCard = ({
  className,
  devices,
  maxDevices,
  minDevices,
  onDevicesChange,
  onMoreInfo,
}) => {
  const classes = useStyles();
  const deviceList = Array(devices)
    .fill(true)
    .concat(Array(maxDevices - devices).fill(false));

  return (
    <Card className={classNames(classes.card, className)}>
      <Typography variant="h3" color="primary" className={classes.heading}>
        Customise your setup with eero
      </Typography>
      <Typography className={classes.text}>
        Get strong and reliable Wi-Fi in every room with Amazon eero. Each
        device is worth £100 each, but you can get them for just £2.50 a month.
        Whole Home Wi-Fi includes eero secure for free, which normally costs £25
        a year. Take control of your Wi-Fi with these features:
      </Typography>
      <ul className={classes.list}>
        <li>
          Protect your family’s personal information, devices and network from
          online threats.
        </li>
        <li>
          Access parental controls, including content filters and the ability to
          block and allow individual sites.
        </li>
        <li>
          Block a variety of ads, which speeds up browsing and helps prevent
          tracking of your online activity.
        </li>
      </ul>
      <Typography className={classes.text}>
        Please note that Eeros devices can only support speeds of up to 500
        Mbps.
      </Typography>
      <Button
        variant="text"
        classes={{ root: classes.buttonRoot, text: classes.buttonText }}
        startIcon={<img src="images/broadband/v2/button-info.svg" />}
        onClick={onMoreInfo}
      >
        Learn more about eero
      </Button>
      <Divider className={classes.divider} />
      <Typography variant="h4" color="primary" className={classes.subHeading}>
        How many eero devices would you like?
      </Typography>
      <Typography className={classes.text}>
        You’ll need at least two eero devices, and can add up to five depending
        on the size of your home.
      </Typography>
      <div className={classes.selectContainer}>
        <div className={classes.selectControls}>
          <IconButton
            color="secondary"
            variant="outlined"
            aria-label="remove device"
            onClick={() => onDevicesChange(devices - 1)}
            disabled={devices <= minDevices}
            classes={{
              root: classes.selectButtonRoot,
              disabled: classes.selectButtonDisabled,
            }}
            data-test-id="bb_router_eero_remove"
          >
            <RemoveIcon fontSize="large" />
          </IconButton>
          <Typography
            variant="h3"
            color="textPrimary"
            align="center"
            className={classes.selectCounter}
          >
            {devices}
          </Typography>
          <IconButton
            color="secondary"
            aria-label="add device"
            onClick={() => onDevicesChange(devices + 1)}
            disabled={devices >= maxDevices}
            classes={{
              root: classes.selectButtonRoot,
              disabled: classes.selectButtonDisabled,
            }}
            data-test-id="bb_router_eero_add"
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.selectDevicesList}>
          {deviceList.map((item, index) => (
            <img
              key={`${index}-${item}`}
              src="images/broadband/v2/eero-item.svg"
              className={classNames(classes.deviceItem, {
                [classes.deviceItemOff]: !item,
              })}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

EeroCard.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.number.isRequired,
  maxDevices: PropTypes.number.isRequired,
  minDevices: PropTypes.number.isRequired,
  onDevicesChange: PropTypes.func.isRequired,
  onMoreInfo: PropTypes.func.isRequired,
};

export default EeroCard;
