import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import EeroCard from './EeroCard';
import EeroDialog from '../Dialogs/Eero';
import RouterSelection from './RouterSelection';
import SectionCentered from 'modules/layout/SectionCentered';

import {
  PACKAGE_NAME_EERO,
  DEVICE_EERO,
} from 'redux/modules/Broadband/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 0),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  routerList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'stretch',
      gap: '16px',
    },
    '& > div': {
      flexGrow: 1,
      flexBasis: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  eeroContainer: {
    marginBottom: theme.spacing(2),
  },
  whichContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  whichLogo: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  whichCopy: {
    maxWidth: '66ch',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
}));

const RouterSelections = ({
  eeroDialogOpen,
  onEeroChange,
  onRouterChange,
  selectedEero,
  selectedRouter,
  selectedTariff,
  setEeroDialogOpen,
  tariffs,
}) => {
  const classes = useStyles();

  const currentTariff = tariffs.find(
    (tariff) => tariff?.meta?.id === selectedTariff
  );
  const eeroRouter = currentTariff?.equipmentPackage?.find(
    (router) => router?.name === PACKAGE_NAME_EERO
  );
  const equipmentEero = eeroRouter?.broadbandEquipment?.find(
    (item) => item?.name === DEVICE_EERO
  );

  return (
    <SectionCentered
      sectionCenteredClasses={{
        root: classes.root,
      }}
    >
      <Typography variant="h2" color="primary" className={classes.title}>
        Choose your WiFi setup
      </Typography>

      <div className={classes.whichContainer}>
        <img
          src="images/awards/which-routers-2023.png"
          alt="Which Award Wi-fi routers 2023"
          width={130}
          height={104}
          className={classes.whichLogo}
        />
        <Typography className={classes.whichCopy} component="p">
          Our Wi-Fi Hub has been award Which? Best Buy status for two years
          running and is included free with all of our broadband packages
        </Typography>
      </div>
      <div className={classes.routerList}>
        {currentTariff.equipmentPackage.map((router) => (
          <RouterSelection
            key={router.name}
            router={alterTariffs(router)}
            onSelect={onRouterChange}
            selected={selectedRouter === router.name}
          />
        ))}
      </div>
      {selectedRouter === PACKAGE_NAME_EERO && (
        <EeroCard
          className={classes.eeroContainer}
          devices={selectedEero}
          minDevices={equipmentEero?.minimumQuantity ?? 2}
          maxDevices={equipmentEero?.maximumQuantity ?? 5}
          onDevicesChange={onEeroChange}
          onMoreInfo={() => setEeroDialogOpen(true)}
        />
      )}
      <EeroDialog open={eeroDialogOpen} setOpen={setEeroDialogOpen} />
    </SectionCentered>
  );
};

// FIXME: Could be implemented by the API
const alterTariffs = (router) => {
  if (!router) return {};

  switch (router.id) {
    case 'WHOLE_HOME':
      return {
        ...router,
        ...{
          bestOnMarket: true,
          description:
            'Combine our UW Wi-Fi Hub with Amazon eero to get the strongest and most reliable Wi-Fi connection across your home.',
          aside: 'Free UW Wi-Fi Hub included',
        },
      };
    case 'WIFI_HUB':
      return {
        ...router,
        ...{
          description:
            'Our free UW Wi-Fi Hub provides good coverage for small to medium-sized homes.',
        },
      };
    case 'NO_ROUTER':
      return {
        ...router,
        ...{ description: 'If you prefer to, you can use your own router.' },
      };
    default:
      return router;
  }
};

RouterSelections.propTypes = {
  eeroDialogOpen: PropTypes.bool,
  onEeroChange: PropTypes.func.isRequired,
  onRouterChange: PropTypes.func.isRequired,
  selectedEero: PropTypes.number.isRequired,
  selectedRouter: PropTypes.string,
  selectedTariff: PropTypes.string.isRequired,
  setEeroDialogOpen: PropTypes.func.isRequired,
  tariffs: PropTypes.array.isRequired,
};

export default React.memo(RouterSelections);
