import React from 'react';
import classNames from 'classnames';
import map from 'lodash/map';

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { InfoOutlined } from '@utilitywarehouse/partner-ui-icons';

const useStyles = makeStyles(({ palette, spacing }) => ({
  expansionPanelRoot: {
    borderRadius: '16px !important',
  },
  detailsConatiner: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3),
  },
  details: {
    display: 'flex',
  },
  bottomLine: {
    borderBottom: `2px solid ${fade(palette.primary.main, 0.2)}`,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  paddingOnly: {
    paddingTop: spacing(2),
  },
  crossIcon: {
    marginRight: spacing(2),
  },
  infoIcon: {
    color: palette.line.primary,
    marginRight: spacing(2),
  },
}));

const ITEMS = [
  'Any amount above the policy limits as shown in the Policy Schedule',
  'Boilers with an output of over 70Kw',
  'Where your home is unoccupied for more than 60 days in a row',
  'Any claim arising from your deliberate act, omission or misrepresentation',
  'Cleaning and general or routine maintenance',
  'Any property owned or used for business or commercial purposes (other than paperwork, telephone calls, child minding and computer work)',
  'Gas leaks',
  'Any claims arising from faulty workmanship, manufacturers defect or recall',
  'Intermittent faults that do not result in a total failure of the primary heating system',
  'Boilers that do not use gas, oil, or liquid petroleum gas',
];

const ListNotCovered = () => {
  const classes = useStyles();

  return (
    <>
      <ExpansionPanel classes={{ root: classes.expansionPanelRoot }}>
        <ExpansionPanelDetails className={classes.detailsConatiner}>
          {map(ITEMS, (text, i) => (
            <div
              key={i}
              className={classNames(classes.details, classes.bottomLine)}
            >
              <CloseIcon color="primary" className={classes.crossIcon} />
              <Typography>{text}</Typography>
            </div>
          ))}

          <div className={classNames(classes.details, classes.paddingOnly)}>
            <InfoOutlined className={classes.infoIcon} />
            <Typography variant="caption">
              For complete pre-contractual and contractual information on the
              product, see the Policy Wording & your Policy Schedule
            </Typography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

export default ListNotCovered;
