import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import { Loading } from '@utilitywarehouse/partner-ui-mui-components';

import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';

import BoilerChoiceContainer from 'redux/modules/BoilerCover/Choice/container';
import BoilerQuoteContainer from 'redux/modules/BoilerCover/Quote/container';
import SectionCentered from 'modules/layout/SectionCentered';

import Covered from './Covered';
import AcceptDialog from './AcceptDialog';
import LoadingDialog from './LoadingDialog';
import useStyles from './styles';

const BoilerCover = ({
  isDriver,
  isQuoteEmpty,
  coveredTabSelected,
  coveredOpen = true,
  clickedDocument,
  hasGetQuoteError,
  acceptQuoteDialogOpen,
  isGetQuoteLoading,
  isEligible,
  isEligibilityLoading,
  hasQuote,
  isAcceptQuoteLoading,
  setCoveredOpen,
  setAcceptQuoteDialogOpen,
  setClickedDocument,
  setSelectedTab,
  signalSecurePage,
  getBoilerCoverQuoteRequest,
  acceptBoilerCoverQuoteRequest,
  triggerEligibilityChecks,
}) => {
  const { variant: bhcBoilerService } = useFeature(
    features.HIDE_BHC_BOILER_SERVICE
  );
  const bhcBoilerServiceEnabled =
    bhcBoilerService === variations[features.HIDE_BHC_BOILER_SERVICE].OFF;

  useEffect(() => {
    triggerEligibilityChecks();
    if (!isQuoteEmpty && isDriver)
      // we don't have any queue logic for signals so we have to wait
      // for the all the peers to connect first, e.g: on page refresh
      setTimeout(() => signalSecurePage(window.location.pathname), 1000);
  }, []);
  const classes = useStyles();
  const trackOnceQuoteAccept = useTrackOnce();

  useEffect(() => {
    if (isEligible && !hasQuote) {
      getBoilerCoverQuoteRequest();
    }
  }, [isEligible, hasQuote]);

  if (isEligibilityLoading) {
    return (
      <div className={classes.loadingWrapper}>
        <Loading loadingText="Checking eligibility" />
      </div>
    );
  }

  return (
    <>
      <LoadingDialog
        open={(isGetQuoteLoading && !!clickedDocument) || isAcceptQuoteLoading}
        onClose={
          isAcceptQuoteLoading ? undefined : () => setClickedDocument(null)
        }
        message={
          isAcceptQuoteLoading ? 'Accepting quote' : 'Generating document'
        }
      />
      <AcceptDialog
        open={acceptQuoteDialogOpen}
        setOpen={setAcceptQuoteDialogOpen}
        onAccept={() => {
          acceptBoilerCoverQuoteRequest();
          trackOnceQuoteAccept(
            EVENTS.INSURANCE_BOILER_COVER_POLICY_QUOTE_ACCEPTED
          );
        }}
      />
      <SectionCentered
        backgroundColor="grey"
        newLayout
        sectionCenteredClasses={{ root: classes.newMainContent }}
      >
        <header className={classes.header}>
          <div className={classes.headerCopy}>
            <Typography variant="h2" color="primary" gutterBottom>
              Moneyfacts 5-star rated gas boiler & home emergency cover
            </Typography>
            <Typography className={classes.subTitle}>
              Emergency repairs on all of your home essentials, from heating and
              plumbing, to drains, roofing and electrics
            </Typography>
          </div>
        </header>
        <ul className={classes.heroFeatures}>
          {bhcBoilerServiceEnabled && (
            <li className={classes.heroFeature}>
              <FlagPointIcon className={classes.heroFeatureIcon} />
              <Typography variant="h4" color="primary">
                Free annual gas boiler service
              </Typography>
            </li>
          )}
          <li className={classes.heroFeature}>
            <FlagPointIcon className={classes.heroFeatureIcon} />
            <Typography variant="h4" color="primary">
              Unlimited call-outs
            </Typography>
          </li>
          <li className={classes.heroFeature}>
            <FlagPointIcon className={classes.heroFeatureIcon} />
            <Typography variant="h4" color="primary">
              24/7 UK claims team
            </Typography>
          </li>
          <li className={classes.heroFeature}>
            <FlagPointIcon className={classes.heroFeatureIcon} />
            <Typography variant="h4" color="primary">
              Parts & labour covered
            </Typography>
          </li>
          <li className={classes.heroFeature}>
            <FlagPointIcon className={classes.heroFeatureIcon} />
            <Typography variant="h4" color="primary">
              Nationwide coverage
            </Typography>
          </li>
        </ul>
        <Typography variant="body2">
          This product is regulated by the FCA and sold on a non-advised basis.
          This means Partners will not discuss this product with you. If you
          have any questions contact UW.
        </Typography>
        <Covered
          open={coveredOpen}
          selected={coveredTabSelected}
          setOpen={(open) => {
            setCoveredOpen(open);
          }}
          setSelectedTab={(tab) => {
            setSelectedTab(tab);
          }}
        />
      </SectionCentered>
      {!hasGetQuoteError && isEligible && <BoilerQuoteContainer />}

      <BoilerChoiceContainer />
    </>
  );
};

BoilerCover.propTypes = {
  isDriver: PropTypes.bool.isRequired,
  isQuoteEmpty: PropTypes.bool.isRequired,
  coveredOpen: PropTypes.bool.isRequired,
  acceptQuoteDialogOpen: PropTypes.bool.isRequired,
  clickedDocument: PropTypes.string,
  coveredTabSelected: PropTypes.string.isRequired,
  isGetQuoteLoading: PropTypes.bool.isRequired,
  isAcceptQuoteLoading: PropTypes.bool.isRequired,
  isEligible: PropTypes.bool.isRequired,
  hasQuote: PropTypes.bool.isRequired,
  isEligibilityLoading: PropTypes.bool.isRequired,
  hasGetQuoteError: PropTypes.bool.isRequired,
  setCoveredOpen: PropTypes.func.isRequired,
  setClickedDocument: PropTypes.func.isRequired,
  setAcceptQuoteDialogOpen: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  signalSecurePage: PropTypes.func.isRequired,
  acceptBoilerCoverQuoteRequest: PropTypes.func.isRequired,
  getBoilerCoverQuoteRequest: PropTypes.func.isRequired,
  triggerEligibilityChecks: PropTypes.func.isRequired,
  isPartner: PropTypes.bool.isRequired,
  isSales: PropTypes.bool.isRequired,
};

export default BoilerCover;
