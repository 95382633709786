import React from 'react';

import { PropTypes } from 'prop-types';
import { LockIcon, UnlockIcon } from '@utilitywarehouse/partner-ui-icons';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import { saving } from 'app/redux/modules/Bundles/propTypes';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

const SavingsList = ({ lockedSavings = [], unlockedSavings }) => {
  const classes = useStyles();
  const ref = React.useRef();
  const trackOnce = useTrackOnce();

  useScrollIntoView(
    ref,
    ({ visible }) => {
      if (visible) trackOnce(EVENTS.MAX_SAVINGS_VIEWPORT_DISPLAYED);
    },
    { threshold: 0.8 }
  );

  return (
    <div ref={ref} className={classes.container}>
      {unlockedSavings
        .filter(({ enabled }) => enabled)
        .map(({ id, description }) => (
          <div key={id} className={classes.listItem}>
            <UnlockIcon className={classes.unlock} />
            <Typography>{description}</Typography>
          </div>
        ))}
      {lockedSavings
        .filter(({ enabled }) => enabled)
        .map(({ id, description }) => (
          <div key={id} className={classes.listItem}>
            <LockIcon className={classes.lock} />
            <Typography className={classes.lockedSaving}>
              {description}
            </Typography>
          </div>
        ))}
    </div>
  );
};

SavingsList.propTypes = {
  lockedSavings: PropTypes.arrayOf(saving).isRequired,
  unlockedSavings: PropTypes.arrayOf(saving).isRequired,
};

export default SavingsList;
