import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles(({ breakpoints, palette, spacing, typography }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(2),
    textAlign: 'center',
    alignItems: 'flex-end',
  },
  legendCell: {
    alignItems: 'center',
    backgroundColor: palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: 75,
    justifyContent: 'flex-start',
    maxWidth: 156,
    padding: spacing(2),
    width: 156,
    '&:nth-of-type(odd):not(:last-of-type)': {
      backgroundColor: palette.background.default,
    },
  },
  cell: {
    backgroundColor: palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: 75,
    justifyContent: 'center',
    padding: spacing(2),
    maxWidth: 184,
    width: 184,

    '&:nth-of-type(even):not(:last-of-type)': {
      backgroundColor: palette.background.default,
    },
  },
  priceCell: {
    justifyContent: 'flex-start',
    padding: spacing(1),
  },
  promoPriceCell: {
    height: 115,
  },
  legendContainer: {
    display: 'none',
    marginTop: 75,
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    '& > *': {
      borderLeft: `2px solid ${palette.primary.main}`,
    },
    '& > *:first-child': {
      borderTop: `2px solid ${palette.primary.main}`,
      borderTopLeftRadius: '16px',
    },
    '& > *:last-child': {
      borderBottomLeftRadius: '16px',
      borderBottom: `2px solid ${palette.primary.main}`,
    },
  },
  tariffsContainer: {
    display: 'flex',
    '& > *:first-child': {
      '& > $tariff': {
        '& > *:first-child': {
          borderTopLeftRadius: '16px',
        },
      },
    },
    '& > *:not(:first-child)': {
      '&:after': {
        backgroundColor: 'rgba(255,255,255, 0.8)',
        content: "''",
        height: 75 + 2, // cell + border
        left: 0,
        position: 'absolute',
        top: 0,
        width: 2,
      },
    },
    '& > *:last-child': {
      '& > $tariff': {
        '& > *': {
          borderRight: `2px solid ${palette.primary.main}`,
        },
        '& > *:first-child': {
          borderTopRightRadius: '16px',
        },
        '& > *:last-child': {
          borderBottomRightRadius: '16px',
        },
      },
    },
  },
  tariffContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  tariff: {
    position: 'relative',
    '& > *:first-child': {
      background: palette.primary.main,
      color: palette.common.white,
    },
    '& > *:nth-child(2)': {
      borderTop: `2px solid ${palette.primary.main}`,
    },
    '& > *:last-child': {
      borderBottom: `2px solid ${palette.primary.main}`,
    },
    '&:before': {
      backgroundColor: fade(palette.primary.main, 0.2),
      bottom: 2,
      content: "''",
      top: 'calc(75px + 2px)', // head cell - border
      position: 'absolute',
      left: 0,
      width: 2,
    },
  },
  titleContainer: {
    fontSize: 20,
    fontWeight: typography.fontWeightMedium,
  },
  titleHighlight: {
    color: palette.services.broadband,
  },
  bold: {
    fontWeight: typography.fontWeightMedium,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  bgColor: {
    backgroundColor: palette.background.default,
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
  },
  promoLabel: {
    fontSize: 13,
  },
  promoStyles: {
    color: palette.success.main,
  },
  asterisk: {
    fontSize: 18,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  outOfContractPrice: {
    marginTop: spacing(1),
  },
}));
