import React from 'react';
import classNames from 'classnames';
import { InfoOutlined } from '@utilitywarehouse/partner-ui-icons';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import ExpansionRow from './ExpansionRow';

const useStyles = makeStyles(({ palette, spacing }) => ({
  details: {
    display: 'flex',
  },
  paddingOnly: {
    paddingTop: spacing(2),
  },
  infoIcon: {
    color: palette.line.primary,
    marginRight: spacing(2),
  },
}));

const ListCovered = () => {
  const classes = useStyles();
  return (
    <>
      <ExpansionRow
        title="Boiler and controls"
        items={[
          'Repairs to your gas boiler and controls, the flue, and the gas supply pipe',
          "Replacement of your gas boiler if we can't repair it and it's less than seven years old, or less than ten years old and you're eligible for the Utility Warehouse Boiler Guarantee",
          'Where we can’t repair your gas boiler, and it isn’t eligible for replacement, we’ll pay the amount detailed in your Schedule towards the cost of a new one',
        ]}
      />
      <ExpansionRow
        title="Central heating"
        items={[
          'Repairs to your central heating on your property',
          'Replacement of central heating parts if we can’t repair them',
          'Accidental damage to your central heating plumbing',
        ]}
      />
      <ExpansionRow
        title="Plumbing"
        items={[
          'Repairs to the plumbing system on your property',
          "Replacement of parts making up the plumbing system on your property that we can't repair",
          'Accidental damage to the plumbing system on your property',
        ]}
      />
      <ExpansionRow
        title="Drains"
        items={[
          'Repairs or unblocking drains to restore flow',
          'Repairs to leaks of internal waste water pipes and external soil and vent pipes',
          "Replacement of parts of your drains that we can't repair",
          'Accidental damage to your drains',
        ]}
      />
      <ExpansionRow
        title="Electrics"
        items={[
          'Repair to the mains electrical system and wiring on your property, including: fuse box, switches, sockets, extractor fans, wired doorbells and wired smoke alarms',
          "Replacement of parts of your mains electrical system and wiring on your property that we can't repair",
          'Accidental damage to your main electrical system and the wiring on your property',
        ]}
      />
      <ExpansionRow
        title="Pests and Security"
        items={[
          'Removal or treatment of pest(s) in your home and wasps or hornet nests on your property',
          'Creating emergency access if you are locked out of your home; boarding up of a broken window or door if it poses a security risk',
          'Repair or replacement of a broken garage door to make your property secure',
          'Repair of the roof of your home, to stop an emergency which has arisen from damage to the roof',
          'Repair or replacement of locks and key where the keys have snapped in the lock or you have lost the keys',
        ]}
      />
      <div className={classNames(classes.details, classes.paddingOnly)}>
        <InfoOutlined className={classes.infoIcon} />
        <Typography variant="caption">
          Exclusions apply&mdash;please refer to the policy documents for full
          details
        </Typography>
      </div>
    </>
  );
};

export default ListCovered;
