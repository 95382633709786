import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link } from '@material-ui/core';
import SectionCentered from 'modules/layout/SectionCentered';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  productIdNameMap,
  PROTECTOR_PRODUCT_CLASS,
  NEW_BP_PRICE,
} from 'redux/modules/BillIncomeProtector/constants';

import useStyles from './styles';

const Footer = ({ selectedQuote }) => {
  const classes = useStyles();

  if (!selectedQuote) return null;
  const isBillProtector =
    productIdNameMap[selectedQuote.product.productId] ===
    PROTECTOR_PRODUCT_CLASS;

  return (
    <SectionCentered
      newLayout
      backgroundColor="white"
      sectionCenteredClasses={{ root: classes.paddingBottom }}
    >
      <Box mt={3} mb={3}>
        <Box mb={3}>
          <Typography variant="h3" component="span" color="primary">
            {productIdNameMap[selectedQuote.product.productId]}: £
            {isBillProtector
              ? NEW_BP_PRICE
              : selectedQuote.charges[0].amount.value}
          </Typography>
          <Typography component="span"> /month</Typography>
        </Box>

        <Typography>
          Your cover will begin once we&apos;ve reviewed and approved your
          application. Cover begins on the first day of the next calendar month.
        </Typography>

        <div className={classes.linkContainer}>
          {selectedQuote.product.details.documents.map((document) => (
            <div className={classes.linkWrapper} key={document.id}>
              <Link
                variant="h5"
                className={classes.link}
                underline="always"
                href={document.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {productIdNameMap[selectedQuote.product.productId]}{' '}
                {document.name}
                <OpenInNewIcon color="secondary" />
              </Link>
            </div>
          ))}
        </div>
        <Typography variant="caption">
          We'll email you personalised documents when the policy starts
        </Typography>
      </Box>
    </SectionCentered>
  );
};

Footer.propTypes = {
  selectedQuote: PropTypes.shape({
    product: PropTypes.shape({
      productClass: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      details: PropTypes.shape({
        documents: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
    charges: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }),
};

export default Footer;
