import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import SectionCentered from 'modules/layout/SectionCentered';
import TextField from 'modules/CustomerUI/TextField';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonGroup from 'modules/Shared/MultiSelection/RadioButtonGroup';
import validateGBNumber from 'app/redux/utils/validateGBNumber';
import PropTypes from 'prop-types';
import { price } from 'app/constants/propTypes';
import { formatPrice } from 'app/redux/utils/formatter';
import ExpandableInfoLabel from 'modules/Shared/ExpandableInfoLabel';
import { CALL_PACKAGE_INFO } from 'modules/Broadband/HomephoneOptions/copy';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(4, 0),
  },
  landlineInput: {
    display: 'inline-block',
  },
  landlineHelpText: {
    fontSize: theme.typography.body2.fontSize,
  },
  section: {
    marginTop: theme.spacing(4),
  },
  radioGroup: {
    paddingBottom: 0,
  },
  extraFeaturesText: {
    marginTop: theme.spacing(1),
  },
}));

const makeCallPackageOptions = (callPackages) =>
  callPackages.map((callPackage) => ({
    value: callPackage.id,
    label: `${callPackage.name}${
      callPackage.cost ? ` (${formatPrice(callPackage.cost)} a month)` : ''
    }`,
  }));

const HomephoneOptions = ({
  homephoneOptions: {
    landlineNumber,
    isVirginMedia,
    selectedCallPackageId,
    callPackages,
  },
  setHomephoneOption,
}) => {
  const classes = useStyles();

  const validNumber = useMemo(() => validateGBNumber(landlineNumber), [
    landlineNumber,
  ]);

  const callPackageOptions = useMemo(
    () => makeCallPackageOptions(callPackages),
    [callPackages]
  );

  return (
    <SectionCentered>
      <Typography variant="h2" color="primary" className={classes.heading}>
        Choose your landline options
      </Typography>
      <Typography>
        If you already have a landline, please enter your number below. If you
        don't know your number or you'd like a new one, we'll need to activate a
        new line for your broadband which will cost{' '}
        <strong>£1 a month for 18 months</strong>
      </Typography>
      <TextField
        textFieldClasses={{
          form: classes.landlineInput,
          helperText: classes.landlineHelpText,
        }}
        value={landlineNumber}
        type="tel"
        fullWidth
        onChange={(_, value) => setHomephoneOption('landlineNumber', value)}
        label="Your landline number"
        helperText="Check your bill, or call a mobile from your landline"
        error={!validNumber && landlineNumber}
        errorMessage={
          validNumber ? '' : 'Please provide a valid landline number'
        }
        dataTestId="BroadbandLandlineNumber"
      />
      {landlineNumber && validNumber && (
        <>
          <RadioButtonGroup
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            radioGroupClasses={{
              container: classes.section,
            }}
            label="Is this a Virgin Media landline?"
            value={isVirginMedia}
            name="BroadbandVirgin"
            onChange={(event) => {
              const value = event.target.value === 'true';
              setHomephoneOption('isVirginMedia', value);
            }}
          />
          {isVirginMedia && (
            <Typography>
              We'll still need to activate a new line for your broadband
            </Typography>
          )}
        </>
      )}
      {callPackages.length > 0 && (
        <div className={classes.section}>
          <ExpandableInfoLabel
            title="Add a call package?"
            info={CALL_PACKAGE_INFO}
          />
          <RadioButtonGroup
            options={callPackageOptions}
            radioGroupClasses={{
              radioGroup: classes.radioGroup,
            }}
            value={selectedCallPackageId}
            onChange={(event) => {
              setHomephoneOption('selectedCallPackageId', event.target.value);
            }}
          />
        </div>
      )}
      <Typography className={classes.extraFeaturesText}>
        To help minimise unwanted sales calls to your landline, we'll add your
        line as "ex-directory" and will register your number with the Telephone
        Preference Service. You can change these settings at any time.
      </Typography>
    </SectionCentered>
  );
};

HomephoneOptions.propTypes = {
  homephoneOptions: PropTypes.shape({
    landlineNumber: PropTypes.string,
    isVirginMedia: PropTypes.bool,
    selectedCallPackageId: PropTypes.string.isRequired,
    callPackages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        cost: price,
      })
    ),
  }).isRequired,
  setHomephoneOption: PropTypes.func.isRequired,
};

export default HomephoneOptions;
