import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    border: 'none',
    margin: '0 auto',
  },
};

class Iframe extends Component {
  componentDidCatch() {
    //ignore the iframe errors, or at least few of them
  }

  render() {
    const { id, src, onLoad, classes } = this.props;

    return (
      <iframe id={id} className={classes.container} src={src} onLoad={onLoad} />
    );
  }
}

Iframe.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Iframe);
