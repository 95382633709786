export const ADD_SERVICES = 'Add services to unlock your savings';
export const YOUR_SAVINGS = 'Your unlocked savings';
export const MAX_SAVINGS = "You've unlocked your maximum savings";

export const getHeadingText = (serviceCount, selectedServices) => {
  if (selectedServices >= serviceCount) {
    return MAX_SAVINGS;
  } else if (selectedServices <= 1) {
    return ADD_SERVICES;
  }
  return YOUR_SAVINGS;
};
