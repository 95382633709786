import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const ServiceTileSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <Skeleton
        animation="wave"
        variant="rect"
        width={48}
        height={48}
        style={{
          display: 'inline-block',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          marginBottom: 8,
        }}
      />
      <Typography variant="h2">
        <Skeleton
          animation="wave"
          width={96}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        />
      </Typography>
      <hr className={classes.separator} />
      <Typography>
        <Skeleton
          animation="wave"
          width={128}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        />
        <Skeleton
          animation="wave"
          width={128}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        />
      </Typography>
    </>
  );
};

export default ServiceTileSkeleton;
