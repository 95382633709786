import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  expansionPanelRoot: {
    borderRadius: spacing(2),
    '&:last-of-type': {
      borderRadius: spacing(2),
    },
  },
  title: {
    ...typography.body1,
    fontWeight: 600,
  },
  detailsConatiner: {
    display: 'flex',
    flexDirection: 'column',

    '& > *:last-child': {
      borderBottom: 'unset',
      paddingBottom: 0,
    },
  },
  details: {
    borderBottom: `2px solid ${fade(palette.primary.main, 0.2)}`,
    display: 'flex',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  doneIcon: {
    marginRight: spacing(2),
  },
}));

const ExpansionRow = ({ title, items }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel classes={{ root: classes.expansionPanelRoot }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="primary" className={classes.title}>
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.detailsConatiner}>
        {map(items, (text, i) => (
          <div key={i} className={classes.details}>
            <DoneIcon color="primary" className={classes.doneIcon} />
            <Typography>{text}</Typography>
          </div>
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ExpansionRow.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default ExpansionRow;
