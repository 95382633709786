import React from 'react';
import PropTypes from 'prop-types';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';

const IneligibleState = ({ onAccept, onDismiss }) => (
  <>
    <RestrictedButton
      onClick={onDismiss}
      dataTestId="BoilerCoverContinue"
      variant="outlined"
      label="Skip"
    />
    <RestrictedButton
      variant="contained"
      onClick={onAccept}
      dataTestId="BoilerCoverRegisterInterest"
      label="Register your interest"
    />
  </>
);

IneligibleState.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default IneligibleState;
