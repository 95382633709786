import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';

import { currency } from 'app/constants/propSchema';
import { formatPrice } from 'redux/utils/formatter';
import { getTariffName } from 'redux/modules/Broadband/helpers';
import Card from 'components/modules/Shared/Card/Card';
import CardHeader from 'components/modules/Shared/Card/CardHeader';
import ContentDropdown from 'components/modules/Shared/ContentDropdown';
import Label from 'components/modules/Shared/Label';
import useStyles from './styles';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';

const getAvgSpeed = (speeds) => {
  const { max, min } = speeds;

  if (!min?.speed) {
    return max.speed;
  }

  return Math.floor((max.speed + min.speed) / 2);
};

export const FormattedTitle = ({ name = '' }) => {
  const classes = useStyles();
  const { title, special } = getTariffName(name);

  return (
    <span>
      {title}
      {special && (
        <>
          {' '}
          <span className={classes.titleHighlight}>{special}</span>
        </>
      )}
    </span>
  );
};

FormattedTitle.propTypes = {
  name: PropTypes.string.isRequired,
};

const TariffSelectionCard = ({
  tariff,
  tariffSelected,
  onSelectTariff,
  ...props
}) => {
  const classes = useStyles();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { variant: hideBbOutOffContractVariant } = useFeature(
    features.HIDE_BB_OUT_OFF_CONTRACT
  );
  const hideBbOutOffContract =
    hideBbOutOffContractVariant ===
    variations[features.HIDE_BB_OUT_OFF_CONTRACT].ON;
  const {
    meta,
    downloadSpeed,
    uploadSpeed,
    contract: { promo, monthlyPriceWithLineRental, extraCostInfo, length },
  } = tariff;

  const hasPromo = !!promo;

  return (
    <div className={classes.cardContainer}>
      {hasPromo && (
        <Label position="right" classes={{ root: classes.promoLabel }}>
          Limited time offer
        </Label>
      )}
      <Card {...props}>
        <CardHeader title={<FormattedTitle name={meta.name} />} />
        <CardContent classes={{ root: classes.content }}>
          <div className={classNames(classes.cardSection, classes.cardTitle)}>
            <Typography>{meta.description}</Typography>
          </div>
          <div className={classes.cardSection}>
            <Typography variant="h2" component="span" color="primary">
              {getAvgSpeed(downloadSpeed)}
            </Typography>
            <Typography component="span"> Mbps</Typography>
            <Typography variant="body2">
              {downloadSpeed.min && downloadSpeed.min.speed > 0
                ? 'Average'
                : 'Estimated'}{' '}
              download speed
            </Typography>

            <ContentDropdown
              label="Detailed speed information"
              fullWidth
              open={detailsOpen}
              onOpen={() => setDetailsOpen(!detailsOpen)}
              classes={{
                root: classes.detailsDropdownRoot,
                label: classes.detailsDropdownTrigger,
                collapse: detailsOpen && classes.detailsDropdown,
              }}
            >
              <Typography variant="body2" gutterBottom>
                Estimated download speed{' '}
                <b>
                  {downloadSpeed.min && downloadSpeed.min.speed > 0 && (
                    <>{downloadSpeed.min.speed}&ndash;</>
                  )}
                  {downloadSpeed.max.speed}
                </b>{' '}
                {downloadSpeed.max.unit}
              </Typography>
              {downloadSpeed.atLeast && downloadSpeed.atLeast.speed > 0 && (
                <Typography variant="body2" gutterBottom>
                  Minimum download speed <b>{downloadSpeed.atLeast.speed}</b>{' '}
                  {downloadSpeed.atLeast.unit}
                </Typography>
              )}
              <Typography variant="body2" gutterBottom>
                Estimated upload speed{' '}
                <b>
                  {uploadSpeed.min && uploadSpeed.min.speed > 0 && (
                    <>{uploadSpeed.min.speed}&ndash;</>
                  )}
                  {uploadSpeed.max.speed}
                </b>{' '}
                {uploadSpeed.max.unit}
              </Typography>
            </ContentDropdown>
          </div>
          <div className={classes.cardSection}>
            <Typography
              variant="h2"
              component="span"
              color="primary"
              className={hasPromo && classes.promoStyles}
            >
              {promo
                ? formatPrice(promo.monthlyPriceWithLineRental)
                : formatPrice(monthlyPriceWithLineRental)}
            </Typography>
            <Typography component="span"> a month</Typography>
            {!hideBbOutOffContract && promo && (
              <Typography variant="body2">
                for {promo.length} months, then{' '}
                <b>{formatPrice(monthlyPriceWithLineRental)}</b> for{' '}
                {length - promo.length} months
              </Typography>
            )}
            {extraCostInfo && (
              <Typography variant="body2">{extraCostInfo}</Typography>
            )}
          </div>
        </CardContent>
        <CardActions>
          <Button
            variant={tariffSelected ? 'contained' : 'outlined'}
            size="large"
            fullWidth
            onClick={() => onSelectTariff(tariff)}
            data-test-id={`bb_tariff_${meta.id}`}
          >
            {tariffSelected ? 'Selected' : 'Select'}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

TariffSelectionCard.propTypes = {
  onSelectTariff: PropTypes.func.isRequired,
  tariffSelected: PropTypes.bool.isRequired,
  tariff: PropTypes.shape({
    tariffLabel: PropTypes.string.isRequired,
    downloadSpeed: PropTypes.shape({
      atLeast: PropTypes.shape({
        speed: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }),
      min: PropTypes.shape({
        speed: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }),
      max: PropTypes.shape({
        speed: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }),
    }).isRequired,
    uploadSpeed: PropTypes.shape({
      min: PropTypes.shape({
        speed: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }),
      max: PropTypes.shape({
        speed: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      }),
    }).isRequired,
    contract: PropTypes.shape({
      monthlyPriceWithLineRental: currency,
      promo: PropTypes.shape({
        id: PropTypes.string,
        length: PropTypes.number,
        monthlyPriceWithLineRental: currency,
      }),
      extraCostInfo: PropTypes.string,
      length: PropTypes.number,
    }).isRequired,
    meta: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default React.memo(TariffSelectionCard);
