import { makeStyles } from '@material-ui/styles';

export default makeStyles(({ palette, spacing, typography }) => ({
  container: {
    borderBottom: `2px solid ${palette.secondary.main}`,
    margin: spacing(4, 0, 2, 0),
  },
  bottomSpacing: {
    marginBottom: spacing(6),
  },
  body: {
    padding: spacing(1, 2),
  },
  bold: {
    fontWeight: typography.fontWeightMedium,
  },
  detailsDropdownlabel: {
    borderBottom: 'none',
    fontWeight: typography.fontWeightMedium,
  },
  detailsDropdownRoot: {
    paddingLeft: 0,
  },
}));
