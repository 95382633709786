import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';

import useStyles from './styles';

const EeroDialog = ({ open, setOpen }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <Typography variant="h3">What’s an eero device?</Typography>
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.imageWrapper}>
          <img
            className={classes.image}
            src="images/broadband/eero.png"
            alt="eero image"
          />
        </div>
        <Typography variant="body1">
          eero devices are like additional routers that you can plug in around
          the house, giving you strong Wi-Fi in every room.
        </Typography>
        <ul className={classes.heroDialogFeatures}>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body1">Each device is worth £100</Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body1">Simple to set up</Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body1">
              Get strong signal in every room
            </Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body1">Wi-Fi 6 compatibility</Typography>
          </li>
          <li className={classes.heroDialogFeature}>
            <FlagPointIcon className={classes.heroDialogFeatureIcon} />
            <Typography variant="body1">
              Includes eero secure, with parental controls for specific devices
              and ad blocking for your whole home - worth £25 per year
            </Typography>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
};

EeroDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default EeroDialog;
