import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const style = (theme) => ({
  titleSection: {
    backgroundColor: theme.palette.common.white,
  },
  title: {
    margin: theme.spacing(2, 0),
  },
  benefitList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: `${theme.spacing(2)}px`,
    margin: theme.spacing(2, 0, 2),
    flexWrap: 'wrap',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: `${theme.spacing(4)}px`,
    },
  },
  tariffTable: {
    margin: theme.spacing(4, 0, 5.5),
  },
  benefitItem: {
    display: 'flex',
    alignItems: 'center',
  },
  benefitIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  tariffListWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  tariffListWrapperAlt: {
    padding: theme.spacing(2, 0),
  },
  tariffList: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  tariffListItemWrapper: {
    alignItems: 'stretch',
    display: 'flex',
    flexBasis: 380,
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      flexGrow: 0,
    },
  },
  tariffListItemWrapperMargin: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  tariffListItemWrapperFullWidth: {
    flexBasis: '100%',
  },
  tariffListItem: {
    flexShrink: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  routerList: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  alert: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    alignItems: 'flex-start',
    padding: theme.spacing(2, 2),
  },
  routerWrapper: {
    marginBottom: theme.spacing(4),
  },
  errorRoot: {
    backgroundColor: theme.palette.background.default,
  },
  summaryContainer: {
    marginBottom: theme.spacing(4),
  },
  discountBubble: {
    backgroundColor: colors.apple200,
    width: 'fit-content',
    marginTop: theme.spacing(3),
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.5),
  },
});

export default makeStyles(style);
