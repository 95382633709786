import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { currency } from 'app/constants/propSchema';
import { formatPrice } from 'redux/utils/formatter';
import {
  getFullPriceNoPromo,
  getFullPriceWithPromo,
  getRouterName,
  getRouterPrice,
  lineActivationPrice,
} from 'redux/modules/Broadband/helpers';
import { price } from 'app/constants/propTypes';
import SummaryPanel from 'modules/Shared/SummaryPanel';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { TRY_BEFORE_YOU_BUY_PROMO } from 'app/redux/modules/Broadband/constants';

const getItems = ({
  tariff,
  router,
  selectedEeroDevices,
  callPackage,
  requiresLineActivation,
}) => {
  const isTBYBPromo = tariff.contract.promo?.id === TRY_BEFORE_YOU_BUY_PROMO;

  return [
    {
      id: tariff.meta.name,
      name: tariff.meta.name,
      price: tariff.contract.monthlyPriceWithLineRental,
      limitedDiscountedPrice: isTBYBPromo
        ? tariff.contract.promo.monthlyPriceWithLineRental
        : undefined,
    },
    {
      id: getRouterName(router),
      name: getRouterName(router),
      price: getRouterPrice(router, selectedEeroDevices),
    },
    callPackage?.cost && {
      id: callPackage.id,
      name: callPackage.name,
      price: callPackage.cost,
    },
    requiresLineActivation && {
      id: 'lineActivations',
      name: 'Line activation',
      price: lineActivationPrice,
    },
  ].filter((item) => !!item);
};

const Summary = ({
  tariff,
  router,
  selectedEeroDevices,
  callPackage,
  requiresLineActivation,
}) => {
  const { variant: hideBbOutOffContractVariant } = useFeature(
    features.HIDE_BB_OUT_OFF_CONTRACT
  );

  if (!router || !tariff) {
    return null;
  }

  const {
    contract: { promo, lineRentalIncluded, lineRentalEndPrice },
  } = tariff;

  const hasPromo = !!promo;
  const hasOneOffCharge =
    router.oneOffCharge.value !== 0 && promo?.id !== TRY_BEFORE_YOU_BUY_PROMO;

  const totalPrice = getFullPriceWithPromo(
    tariff,
    router,
    selectedEeroDevices,
    callPackage,
    requiresLineActivation
  );
  const items = getItems({
    tariff,
    router,
    selectedEeroDevices,
    callPackage,
    requiresLineActivation,
  });

  const renderExtraInfo = () => {
    const hideBbOutOffContract =
      hideBbOutOffContractVariant ===
      variations[features.HIDE_BB_OUT_OFF_CONTRACT].ON;
    const showExtraInfo = !hideBbOutOffContract && hasPromo;

    if (!showExtraInfo) {
      return null;
    }

    return (
      <>
        for {promo.length} months, then{' '}
        <b>
          {formatPrice(
            getFullPriceNoPromo(
              tariff,
              router,
              selectedEeroDevices,
              callPackage
            )
          )}
        </b>
      </>
    );
  };

  return (
    <SummaryPanel
      items={items}
      monthlyTotal={totalPrice}
      discountedMonthlyTotal={hasPromo ? totalPrice : undefined}
      monthlyTotalExtraInfo={renderExtraInfo()}
    >
      {hasOneOffCharge && (
        <div>
          <Typography variant="body2">
            A {formatPrice(router.oneOffCharge)} delivery charge will be added
            to your first bill.
          </Typography>
          {lineRentalIncluded && (
            <Typography variant="body2">
              Price includes {formatPrice(lineRentalEndPrice)} line rental
            </Typography>
          )}
        </div>
      )}
    </SummaryPanel>
  );
};

Summary.propTypes = {
  selectedEeroDevices: PropTypes.number,
  router: PropTypes.shape({
    name: PropTypes.string.isRequired,
    monthlyCharge: currency,
    oneOffCharge: currency,
    broadbandEquipment: PropTypes.array.isRequired,
  }).isRequired,
  tariff: PropTypes.shape({
    contract: PropTypes.shape({
      monthlyPriceWithLineRental: currency,
      promo: PropTypes.shape({
        id: PropTypes.string,
        length: PropTypes.number,
        monthlyPriceWithLineRental: currency,
      }),
      lineRentalIncluded: PropTypes.bool,
      lineRentalEndPrice: currency,
    }).isRequired,
    meta: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
  callPackage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cost: price,
  }),
  requiresLineActivation: PropTypes.bool.isRequired,
};

export default Summary;
