import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  mainContent: {
    paddingTop: theme.spacing(3),
  },
  newMainContent: {
    paddingBottom: theme.spacing(6),
  },
  header: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: `${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(4),
    },
  },
  headerCopy: {
    flexGrow: 1,
  },
  headerImage: {
    maxWidth: '30ch',

    '& > img': {
      width: '100%',
    },
  },
  subTitle: {
    fontSize: 20,
  },
  section: {
    padding: theme.spacing(6, 0),
  },
  heroFeatures: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}px`,
    margin: 0,
    padding: theme.spacing(0, 0, 6, 0),
    listStyle: 'none',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  heroFeature: {
    flex: `0 0 calc(33% - ${theme.spacing(4)}px)`,
    display: 'flex',
    alignItems: 'center',
    columnGap: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      columnGap: 0,
    },
  },
  heroFeatureIcon: {
    color: theme.palette.success.main,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
      fontSize: 48,
    },
  },
  caption: {
    display: 'block',
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '25dvh',
  },
});

export default makeStyles(style);
