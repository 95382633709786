import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AlertIcon } from '@utilitywarehouse/partner-ui-icons';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';

import SectionCentered from 'modules/layout/SectionCentered';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import ContentDropdown from 'modules/Shared/ContentDropdown';
import {
  ContentListTabs,
  ContentListTab,
  ContentListPanel,
} from 'modules/Shared/ContentList';
import AcceptDialog from './AcceptDialog';
import {
  TAB_COVERED,
  TAB_NOT_COVERED,
} from 'redux/modules/BillIncomeProtector/constants';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import { PRODUCT_ID_INCOME_PROTECTOR } from 'redux/modules/BillIncomeProtector/constants';

import QuoteTable from './QuoteTable';
import Footer from './Footer';
import useStyles from './styles';

const BillIncomeProtector = ({
  billProtectorEligible,
  incomeProtectorEligible,
  acceptDialogOpen,
  setAcceptDialog,
  setCoveredDropdown,
  setCoveredTab,
  tabs,
  acceptQuote,
  acceptQuoteLoading,
  skip,
  fetchQuotes,
  quotes,
  quotesLoading,
  setQuoteSelection,
  selectedQuote,
  isOrganic,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const trackOnceBill = useTrackOnce();
  const trackOnceIncome = useTrackOnce();
  const trackOnceModal = useTrackOnce();
  const trackOnceAcceptQuote = useTrackOnce();
  const trackOnceNoThanks = useTrackOnce();
  const trackOncePoliciesDisplayed = useTrackOnce();
  const protectorSelectionRef = useRef();

  const hideBillProtector = isOrganic;

  useScrollIntoView(
    protectorSelectionRef,
    ({ visible }) => {
      if (visible)
        trackOncePoliciesDisplayed(
          EVENTS.INSURANCE_PROTECTOR_POLICIES_DISPLAYED
        );
    },
    { threshold: 1.0 }
  );

  const handleAcceptProtector = (quote) => {
    trackOnceAcceptQuote(EVENTS.INSURANCE_PROTECTOR_QUOTE_ACCEPTED);
    acceptQuote(quote);
  };

  const currentQuote = quotes.find((q) => q.quoteId === selectedQuote);

  useEffect(() => {
    fetchQuotes();
    return () => {};
  }, [fetchQuotes]);

  useEffect(() => {
    if (hideBillProtector && !quotesLoading) {
      const incomeProtectorQuote = quotes?.find(
        (quote) => quote.product.productId === PRODUCT_ID_INCOME_PROTECTOR
      );
      setQuoteSelection(incomeProtectorQuote?.quoteId);
      setCoveredDropdown('income', !tabs.income.open);
    }
  }, [quotes]);

  return (
    <>
      <SectionCentered newLayout>
        <Box mt={3} mb={3}>
          <Typography
            variant="h2"
            component="h2"
            color="primary"
            className={classes.title}
          >
            {hideBillProtector
              ? 'Income Protector can help safeguard your bills'
              : 'UW can help safeguard your bills'}
          </Typography>
          <Typography className={classes.bodyText}>
            If you can’t work due to illness, injury or unemployment through no
            fault of your own, then{' '}
            {!hideBillProtector && 'Bill Protector and '} Income Protector can
            help to replace some of your earnings – giving you the support you
            need when you need it most.
          </Typography>
          {hideBillProtector && (
            <>
              <ul className={classes.heroFeatures}>
                <li className={classes.heroFeature}>
                  <FlagPointIcon className={classes.heroFeatureIcon} />
                  <Typography variant="h4" color="primary">
                    Covers up to £750 for 6 months if unemployed
                  </Typography>
                </li>
                <li className={classes.heroFeature}>
                  <FlagPointIcon className={classes.heroFeatureIcon} />
                  <Typography variant="h4" color="primary">
                    Covers Hospitalisation costs for 30 days
                  </Typography>
                </li>
                <li className={classes.heroFeature}>
                  <FlagPointIcon className={classes.heroFeatureIcon} />
                  <Typography variant="h4" color="primary">
                    Accidental Death Cover offers £10,000 lump sum
                  </Typography>
                </li>
              </ul>
              <Typography variant="caption">
                This product is regulated by the FCA and sold on a non-advised
                basis. This means our Partners are not authorised to answer
                questions about it.
              </Typography>
            </>
          )}
        </Box>
      </SectionCentered>
      <SectionCentered
        newLayout
        sectionCenteredClasses={{ root: classes.paddingBottom }}
      >
        {!hideBillProtector && (
          <>
            {' '}
            <Typography
              variant="h2"
              component="h2"
              color="primary"
              className={classes.title}
            >
              Choose your cover level
            </Typography>
            <div ref={protectorSelectionRef}>
              <QuoteTable
                quotes={quotes}
                loading={quotesLoading}
                onChange={(quoteId) => setQuoteSelection(quoteId)}
                selected={selectedQuote}
                billProtectorEligible={billProtectorEligible}
                incomeProtectorEligible={incomeProtectorEligible}
              />
            </div>
            <Box mt={3} mb={3}>
              <Typography>
                Don't forget, Bill Protector won't count towards unlocking your
                savings. This product is regulated by the FCA and sold on a
                non-advised basis. This means our Partners are not authorised to
                answer questions about it.
              </Typography>
            </Box>
          </>
        )}

        <Box mt={3} mb={3}>
          <Typography
            variant="h2"
            component="h2"
            color="primary"
            className={classes.title}
          >
            Only available to new customers – so don’t wait
          </Typography>
          {hideBillProtector ? (
            <Typography className={classes.bodyText}>
              Income Protector is only available to buy during the first 30 days
              as a UW customer. So make sure you get your cover in place before
              your window closes.
            </Typography>
          ) : (
            <Typography className={classes.bodyText}>
              Both Bill Protector and Income Protector are{' '}
              <strong className={classes.bold}>
                only available to buy during the first 30 days as a UW customer.
              </strong>{' '}
              So make sure you get your cover in place before your window
              closes.
            </Typography>
          )}
        </Box>

        <Box mb={3}>
          {!hideBillProtector && (
            <ContentDropdown
              fullWidth
              label={
                <Typography variant="h5" component="h3">
                  Bill Protector - What's covered
                </Typography>
              }
              open={tabs.bill.open}
              onOpen={() => {
                trackOnceBill(
                  EVENTS.INSURANCE_BILL_PROTECTOR_WHATS_COVERED_CLICKED
                );
                setCoveredDropdown('bill', !tabs.bill.open);
              }}
              classes={{ root: classes.contentDropdownButton }}
            >
              <>
                <ContentListTabs
                  selected={tabs.bill.tab}
                  onChange={setCoveredTab.bind(null, 'bill')}
                >
                  <ContentListTab value={TAB_COVERED} label="Things we cover" />
                  <ContentListTab
                    value={TAB_NOT_COVERED}
                    label="Things we don't cover"
                  />
                </ContentListTabs>
                {tabs.bill.tab === TAB_COVERED && (
                  <>
                    <ContentListPanel
                      title="Unemployment & illness cover"
                      items={[
                        'The policy provides cover for your UW bill (maximum payable each month is £450) if you cannot work due to illness, injury or involuntary unemployment. ',
                        'This benefit is credited to your Utility Warehouse account.',
                        'The policy provides up to 6 monthly benefit payments for any one claim.',
                      ]}
                    />
                    <ContentListPanel
                      title="Hospitalisation cover"
                      items={[
                        'The policy provides cover if you are hospitalised as a result of an accident.',
                        'The insurer will pay £25 for each complete 24 hours that you remain in hospital, for a maximum of 30 days.',
                        'This benefit is paid to you directly.',
                      ]}
                    />
                    <ContentListPanel
                      title="Personal accident cover"
                      items={[
                        'The policy provides a lump sum payment of up to £10,000 if you suffer bodily injury from an accident, accidental death, loss of limb, loss of sight or permanent total disablement.',
                        'Benefit is paid to you or your estate.',
                      ]}
                    />
                  </>
                )}
                {tabs.bill.tab === TAB_NOT_COVERED && (
                  <>
                    <ContentListPanel
                      title="All cover"
                      Icon={AlertIcon}
                      items={[
                        'Side effects from medicines, medicines incorrectly taken, medicines for treating drug addiction, stress, anxiety or depression.',
                        'Known risks associated with medical or surgical procedures.',
                        'Issues resulting from hazardous sports and activities.',
                      ]}
                    />
                    <ContentListPanel
                      title="Unemployment & illness cover"
                      Icon={AlertIcon}
                      items={[
                        'If your work is temporary, casual, occasional or on a contract basis which does not extend beyond 12 months.',
                        'Any period for which you have received a payment instead of working a notice period.',
                        'Unemployment which is notified to you during the 90 day period immediately following the start date of your insurance.',
                        'No benefits are payable under the injury/illness section of the policy after age 75.',
                        'Voluntary unemployment or unemployment due to your misconduct.',
                      ]}
                    />
                    <ContentListPanel
                      title="Hospitalisation cover"
                      Icon={AlertIcon}
                      items={['Hospitalisation due to an illness.']}
                    />
                    <ContentListPanel
                      title="Personal accident cover"
                      Icon={AlertIcon}
                      items={[
                        'That results from you engaging in any flying other than as a passenger.',
                      ]}
                    />
                  </>
                )}
              </>
            </ContentDropdown>
          )}
        </Box>
        <Box mb={3}>
          <ContentDropdown
            fullWidth
            label={
              <Typography variant="h5" component="h3">
                {hideBillProtector
                  ? "What's covered"
                  : "Income Protector - What's covered"}
              </Typography>
            }
            open={tabs.income.open}
            onOpen={() => {
              trackOnceIncome(
                EVENTS.INSURANCE_INCOME_PROTECTOR_WHATS_COVERED_CLICKED
              );
              setCoveredDropdown('income', !tabs.income.open);
            }}
            classes={{ root: classes.contentDropdownButton }}
          >
            <>
              <ContentListTabs
                selected={tabs.income.tab}
                onChange={setCoveredTab.bind(null, 'income')}
              >
                <ContentListTab value={TAB_COVERED} label="Things we cover" />
                <ContentListTab
                  value={TAB_NOT_COVERED}
                  label="Things we don't cover"
                />
              </ContentListTabs>
              {tabs.income.tab === TAB_COVERED && (
                <>
                  <ContentListPanel
                    title="Unemployment & illness cover"
                    items={[
                      'The policy provides cover for 65% of your pre-tax monthly income (up to a maximum of £750) if you cannot work due to illness, injury or involuntary unemployment.',
                      'This benefit is paid to you directly.',
                      'The policy provides up to 6 monthly benefit payments for any one claim.',
                    ]}
                  />
                  <ContentListPanel
                    title="Hospitalisation cover"
                    items={[
                      'The policy provides cover if you are admitted as a hospital inpatient.',
                      'The insurer will pay £50 for each complete 24 hours that you remain in hospital.',
                      'This benefit is paid to you directly.',
                    ]}
                  />
                  <ContentListPanel
                    title="Personal accident cover"
                    items={[
                      'The policy provides a lump sum payment of up to £10,000 if you suffer bodily injury from an accident, accidental death, loss of limb, loss of sight or permanent total disablement.',
                      'Benefit is paid to you or your estate.',
                    ]}
                  />
                </>
              )}
              {tabs.income.tab === TAB_NOT_COVERED && (
                <>
                  <ContentListPanel
                    title="All cover"
                    Icon={AlertIcon}
                    items={[
                      'Side effects from medicines, medicines incorrectly taken, medicines for treating drug addiction, stress, anxiety or depression.',
                      'Known risks associated with medical or surgical procedures.',
                      'Issues resulting from hazardous sports and activities.',
                    ]}
                  />
                  <ContentListPanel
                    title="Unemployment & illness cover"
                    Icon={AlertIcon}
                    items={[
                      'If your work is temporary, casual, occasional or on a contract basis which does not extend beyond 12 months.',
                      'Any period for which you have received a payment instead of working a notice period.',
                      'Unemployment which is notified to you during the 90 day period immediately following the start date of your insurance.',
                      'No benefits are payable under the injury/illness section of the policy after age 75.',
                      'Voluntary unemployment or unemployment due to your misconduct.',
                      'Unemployment which is normal or seasonal in your occupation.',
                      'Any pre-existing medical condition in the 12 months before the start of cover.',
                    ]}
                  />
                  <ContentListPanel
                    title="Hospitalisation cover"
                    Icon={AlertIcon}
                    items={['Hospitalisation due to an illness.']}
                  />
                  <ContentListPanel
                    title="Personal accident cover "
                    Icon={AlertIcon}
                    items={[
                      'That results from you engaging in any flying other than as a passenger.',
                      'Which is caused by gradual loss or use of function.',
                    ]}
                  />
                </>
              )}
            </>
          </ContentDropdown>
        </Box>
      </SectionCentered>
      {selectedQuote && <Footer selectedQuote={currentQuote} />}
      <NavigationPane
        helpCtaEnabled
        back
        next
        marginTopZero
        topColor={selectedQuote ? theme.palette.common.white : undefined}
        disabled={!selectedQuote || quotesLoading}
        clickHandler={() => {
          trackOnceModal(EVENTS.INSURANCE_PROTECTOR_POLICY_REVIEWED);
          setAcceptDialog(true);
        }}
        label="Add cover"
      >
        <RestrictedButton
          variant="outlined"
          label="Skip"
          onClick={() => {
            trackOnceNoThanks(EVENTS.INSURANCE_PROTECTOR_NO_THANKS_CLICKED);
            skip();
          }}
          dataTestId="BipSkip"
        />
      </NavigationPane>
      <AcceptDialog
        open={acceptDialogOpen}
        setOpen={setAcceptDialog}
        onAccept={handleAcceptProtector}
        loading={acceptQuoteLoading}
        selectedProduct={currentQuote?.product?.name}
      />
    </>
  );
};

BillIncomeProtector.propTypes = {
  billProtectorEligible: PropTypes.bool.isRequired,
  incomeProtectorEligible: PropTypes.bool.isRequired,
  acceptDialogOpen: PropTypes.bool.isRequired,
  setAcceptDialog: PropTypes.func.isRequired,
  setCoveredDropdown: PropTypes.func.isRequired,
  setCoveredTab: PropTypes.func.isRequired,
  acceptQuote: PropTypes.func.isRequired,
  acceptQuoteLoading: PropTypes.bool.isRequired,
  fetchQuotes: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  setQuoteSelection: PropTypes.func.isRequired,
  selectedQuote: PropTypes.string,
  tabs: PropTypes.shape({
    bill: PropTypes.shape({
      open: PropTypes.bool.isRequired,
      tab: PropTypes.oneOf([TAB_COVERED, TAB_NOT_COVERED]).isRequired,
    }),
    income: PropTypes.shape({
      open: PropTypes.bool.isRequired,
      tab: PropTypes.oneOf([TAB_COVERED, TAB_NOT_COVERED]).isRequired,
    }),
  }),
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quoteId: PropTypes.string.isRequired,
    })
  ).isRequired,
  quotesLoading: PropTypes.bool.isRequired,
  isOrganic: PropTypes.bool.isRequired,
};

export default BillIncomeProtector;
