import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { WarningOutlined } from '@utilitywarehouse/partner-ui-icons';

import SectionCentered from 'modules/layout/SectionCentered';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import NavigationButtons from './NavigationButtons';
import useStyles from './styles';

const Choice = ({ showHeader, isEligible, hasGetQuoteError, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      {showHeader && !hasGetQuoteError && isEligible && (
        <SectionCentered
          backgroundColor="white"
          newLayout
          sectionCenteredClasses={{ root: classes.container }}
        >
          <header className={classes.header}>
            <Typography variant="h2" color="primary" gutterBottom>
              Your cover
            </Typography>
            <Typography className={classes.subTitle}>
              Your cover will begin once we've reviewed and approved your
              application. We will confirm the exact policy start date via
              email.
            </Typography>
          </header>
        </SectionCentered>
      )}
      {hasGetQuoteError && (
        <SectionCentered
          backgroundColor="white"
          newLayout
          sectionCenteredClasses={{ root: classes.container }}
        >
          <Alert
            severity="error"
            variant="standard"
            classes={{ root: classes.alert }}
            icon={<WarningOutlined />}
          >
            <AlertTitle>Your quote failed to load</AlertTitle>
            Sorry about this! Don't worry, we'll remind you about this again
            when your account is live.
          </Alert>
        </SectionCentered>
      )}
      <NavigationPane
        helpCtaEnabled
        topColor={showHeader ? theme.palette.common.white : undefined}
        back
      >
        <NavigationButtons
          isEligible={isEligible}
          hasGetQuoteError={hasGetQuoteError}
          {...props}
        />
      </NavigationPane>
    </>
  );
};

Choice.propTypes = {
  showHeader: PropTypes.bool.isRequired,
  isEligible: PropTypes.bool.isRequired,
  hasGetQuoteError: PropTypes.bool.isRequired,
};

export default Choice;
