import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = (theme) => ({
  legend: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  legendLabel: {
    position: 'absolute',
    visibility: 'hidden',
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
    },
  },
  legendItem: {
    flexGrow: 1,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'right',
  },
  maximumSavingsLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'right',
    lineHeight: 1.6,
  },
  progressRoot: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    height: theme.spacing(1.5),
  },
  progressBar: {
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.main,
  },
});

export default makeStyles(styles);
