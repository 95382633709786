import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, palette }) => ({
  container: {
    paddingBottom: spacing(6),
  },
  header: {
    marginBottom: spacing(3),
  },
  subTitle: {
    fontSize: 20,
  },
  alert: {
    border: `2px solid ${palette.error.main}`,
  },
}));
