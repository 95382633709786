import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    maxWidth: 1030,
  },
  confettiBar: {
    width: '100%',
    background: 'url(images/bundlesPage/confetti.svg)',
    backgroundRepeat: 'repeat-x',
    backgroundPositionY: 'center',
    height: 60,
  },
  topBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 4),
  },
  paper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: 24,
    gridColumn: 'span 2',
  },
  energyBundle: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  electricity: {
    height: 40,
    marginRight: theme.spacing(2),
    marginTop: 6,
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.line.primary,
  },
});

export default makeStyles(styles);
