import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { CardContent, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';
import { SuccessIcon, LockIcon } from '@utilitywarehouse/partner-ui-icons';
import { images } from '@utilitywarehouse/partner-ui-assets';
import { price as pricePropType } from 'app/constants/propTypes';
import { formatPrice } from 'redux/utils/formatter';

import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import {
  PRODUCT_ENERGY,
  PRODUCT_BROADBAND,
  PRODUCT_MOBILE,
  PRODUCT_MOBILE_2,
  PRODUCT_INSURANCE,
  productMap,
  productDescriptions,
  PRODUCT_INSURANCE_TENANTS,
  PRODUCT_INSURANCE_NO_BIP,
  productPriceDescriptions,
  DEFAULT_PRICE_DESCRIPTION,
  PRODUCT_INSURANCE_NEW,
  PRODUCT_INSURANCE_TENANTS_NEW,
  PRODUCT_INSURANCE_TENANTS_NEW_NO_BIP,
  PRODUCT_INSURANCE_NEW_NO_BIP,
} from 'redux/modules/Bundles/constants';

import useStyles from './styles';
import ServiceTileSkeleton from './ServiceTileSkeleton';

const getDisabledProtectorsDescriptor = (isHomeInsuranceEnabled, isTenant) => {
  if (!isHomeInsuranceEnabled) {
    return PRODUCT_INSURANCE_NO_BIP;
  }

  return isTenant
    ? PRODUCT_INSURANCE_TENANTS_NEW_NO_BIP
    : PRODUCT_INSURANCE_NEW_NO_BIP;
};

const getEnabledProtectorsDescriptor = (isHomeInsuranceEnabled, isTenant) => {
  if (isTenant) {
    return isHomeInsuranceEnabled
      ? PRODUCT_INSURANCE_TENANTS_NEW
      : PRODUCT_INSURANCE_TENANTS;
  }

  return isHomeInsuranceEnabled ? PRODUCT_INSURANCE_NEW : PRODUCT_INSURANCE;
};

const getInsuranceDescription = ({
  protectorsDisabled,
  isHomeInsuranceEnabled,
  isTenant,
}) => {
  let descriptor;

  if (protectorsDisabled) {
    descriptor = getDisabledProtectorsDescriptor(
      isHomeInsuranceEnabled,
      isTenant
    );
  } else {
    descriptor = getEnabledProtectorsDescriptor(
      isHomeInsuranceEnabled,
      isTenant
    );
  }

  return productDescriptions[descriptor];
};

const ServiceIcon = ({ service }) => {
  const classes = useStyles();
  let icon;
  switch (service) {
    case PRODUCT_ENERGY:
      icon = images.icons.productIcons.Electricity;
      break;
    case PRODUCT_BROADBAND:
      icon = images.icons.productIcons.Broadband;
      break;
    case PRODUCT_MOBILE:
    case PRODUCT_MOBILE_2:
      icon = images.icons.productIcons.Mobile;
      break;
    case PRODUCT_INSURANCE:
      icon = images.icons.productIcons.Insurance;
      break;
    default:
      return null;
  }

  return <img src={icon} alt="" className={classes.serviceIcon} />;
};

ServiceIcon.propTypes = {
  service: PropTypes.string.isRequired,
};

const ServiceTilePrice = ({ service, discountPrice }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Typography
      className={clsx(classes.priceContainer, {
        [classes.priceUnselected]: !service.selected,
      })}
    >
      {productPriceDescriptions[service.id] || DEFAULT_PRICE_DESCRIPTION}
      <span className={classes.priceValues}>
        <span>
          <span className={classes.price}>
            {formatPrice(discountPrice ?? service.price)}
          </span>
          /mo{isDesktop && 'nth'}
        </span>
      </span>
    </Typography>
  );
};

ServiceTilePrice.propTypes = {
  service: PropTypes.shape({
    price: pricePropType,
    selected: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
  }),
  discountPrice: pricePropType,
};

const ServiceTile = ({
  onChange,
  service,
  loading,
  discountPrice,
  isTenant,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const tileRef = useRef(null);
  const isHomeInsuranceEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.INSURANCE_HOME_INSURANCE_ENABLED)
  );

  const protectorsDisabledSelector = FeatureFlagsApi.getFlagSelector(
    FLAGS.INSURANCE_PROTECTORS_DISABLED
  );
  const protectorsDisabled = useSelector(protectorsDisabledSelector);

  if (!service) return null;
  const lockedReason = service.available
    ? 'Add a service to unlock'
    : 'Unavailable in your area';

  const isInsuranceProduct = service.id === PRODUCT_INSURANCE;

  if (isInsuranceProduct && !loading) {
    if (!service.available) {
      return null;
    }
  }

  const productDescription = isInsuranceProduct
    ? getInsuranceDescription({
        protectorsDisabled,
        isHomeInsuranceEnabled,
        isTenant,
      })
    : productDescriptions[service.id];

  return (
    <div className={classes.root} data-test-id={`service-${service.id}`}>
      <CheckCard
        variant="checkbox"
        value={service}
        name="mobilePack"
        onChange={() => {
          onChange(service.id);
          // force blur to remove focus styles on element
          // when selected/deselected
          tileRef.current.lastChild.blur();
        }}
        checked={service.selected}
        disabled={!service.enabled || loading}
        classes={{
          buttonBase: clsx(classes.cardButton, {
            [classes.cardButtonChecked]: service.selected,
            [classes.cardButtonDisabled]: !service.enabled && !loading,
            [classes.cardButtonLoading]: loading,
          }),
        }}
        ref={tileRef}
      >
        <CardContent className={classes.cardContent}>
          {loading && <ServiceTileSkeleton />}
          {!loading && (
            <>
              <header
                className={clsx(classes.header, {
                  [classes.headerUnchecked]:
                    !service.selected && service.enabled,
                })}
              >
                <div className={classes.titleWrapper}>
                  <ServiceIcon service={service.id} />
                  <Typography
                    variant="h4"
                    component="span"
                    className={classes.title}
                  >
                    {productMap[service.id]}
                  </Typography>
                </div>

                {// `false` forcefully hides the price, while the additional
                // mobile tile is active. Prevents having to calculate
                // additioal mobile price discounts
                //
                // TODO: undo or remove price once we become certain we're
                // keeping it or not
                false &&
                  !isDesktop &&
                  service?.enabled &&
                  service?.price &&
                  !loading && (
                    <ServiceTilePrice
                      service={service}
                      discountPrice={discountPrice}
                    />
                  )}
                {!isDesktop && !service?.enabled && (
                  <LockIcon className={classes.lockedIcon} />
                )}
              </header>
              <hr className={classes.separator} />
              <div className={classes.description}>
                {service.enabled && (
                  <Typography className={classes.descriptionText}>
                    {productDescription}
                  </Typography>
                )}
                {!service.enabled && (
                  <>
                    {isDesktop && <LockIcon className={classes.lockedIcon} />}
                    <Typography className={classes.descriptionText}>
                      {lockedReason}
                    </Typography>
                  </>
                )}
              </div>
            </>
          )}
        </CardContent>
      </CheckCard>
      {service.selected && <SuccessIcon className={classes.selectedIcon} />}
      {false && isDesktop && service?.enabled && service?.price && !loading && (
        <ServiceTilePrice service={service} discountPrice={discountPrice} />
      )}
    </div>
  );
};

ServiceTile.propTypes = {
  onChange: PropTypes.func.isRequired,
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    available: PropTypes.bool.isRequired,
    price: pricePropType,
  }).isRequired,
  discountPrice: pricePropType,
  loading: PropTypes.bool,
  isTenant: PropTypes.bool,
};

export default ServiceTile;
