import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Typography } from '@material-ui/core';

import { currency } from 'app/constants/propSchema';
import { formatPrice } from 'redux/utils/formatter';
import {
  getTariffName,
  getTariffPriceWithLineRental,
} from 'redux/modules/Broadband/helpers';
import useStyles from './styles';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import Label from '../../Shared/Label';

const FormattedTitle = ({ name = '', classes }) => {
  const { title, special } = getTariffName(name);

  return (
    <span className={classes.titleContainer}>
      {title}
      {special && (
        <span className={classes.titleHighlight}>{` ${special}`}</span>
      )}
    </span>
  );
};

FormattedTitle.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
};

const TariffTable = ({ tariffs, selectedTariffId, onSelectTariff }) => {
  const classes = useStyles();
  const { variant: hideBbOutOffContractVariant } = useFeature(
    features.HIDE_BB_OUT_OFF_CONTRACT
  );
  const hideBbOutOffContract =
    hideBbOutOffContractVariant ===
    variations[features.HIDE_BB_OUT_OFF_CONTRACT].ON;

  const showMinDownSpeed = tariffs.every((t) => t.downloadSpeed.min);
  const hasPromoTariff = tariffs.some((t) => !!t.contract?.promo);

  return (
    <div className={classes.container}>
      <div className={classes.legendContainer}>
        <div className={classes.legendCell}>
          <Typography classes={{ root: classes.bold }}>Estimated</Typography>
          <Typography classes={{ root: classes.bold }}>download</Typography>
        </div>
        {showMinDownSpeed && (
          <div className={classes.legendCell}>
            <Typography classes={{ root: classes.bold }}>Minimum</Typography>
            <Typography classes={{ root: classes.bold }}>download</Typography>
          </div>
        )}
        <div className={classes.legendCell}>
          <Typography classes={{ root: classes.bold }}>Estimated</Typography>
          <Typography classes={{ root: classes.bold }}>upload</Typography>
        </div>
        <div
          className={classNames(
            classes.legendCell,
            hasPromoTariff && classes.promoPriceCell
          )}
        >
          <Typography classes={{ root: classes.bold }}>Price</Typography>
        </div>
        <div className={classes.legendCell} />
      </div>
      <div className={classes.tariffsContainer}>
        {tariffs.map((tariff) => {
          const {
            tariffLabel,
            downloadSpeed,
            uploadSpeed,
            contract,
            meta,
          } = tariff;

          const hasPromo = !!contract?.promo;

          return (
            <div key={tariffLabel} className={classes.tariffContainer}>
              {hasPromo && (
                <Label position="right" classes={{ text: classes.promoLabel }}>
                  Limited time offer
                </Label>
              )}
              <div className={classNames(classes.tariff)}>
                <div className={classNames(classes.cell, classes.title)}>
                  <FormattedTitle classes={classes} name={meta.name} />
                </div>
                <div className={classes.cell}>
                  <Typography
                    color="primary"
                    classes={{ root: classes.subtitle }}
                  >
                    {downloadSpeed.min && <>{downloadSpeed.min.speed}&ndash;</>}
                    {downloadSpeed.max.speed}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {downloadSpeed.max.unit}
                  </Typography>
                </div>
                {downloadSpeed.atLeast && (
                  <div className={classes.cell}>
                    <>
                      <Typography
                        color="primary"
                        classes={{ root: classes.subtitle }}
                      >
                        {downloadSpeed.atLeast.speed}
                      </Typography>
                      <Typography variant="body2" component="span">
                        {downloadSpeed.atLeast.unit}
                      </Typography>
                    </>
                  </div>
                )}
                <div className={classes.cell}>
                  <Typography
                    color="primary"
                    classes={{ root: classes.subtitle }}
                  >
                    {uploadSpeed.min && <>{uploadSpeed.min.speed}&ndash;</>}
                    {uploadSpeed.max.speed}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {uploadSpeed.max.unit}
                  </Typography>
                </div>
                <div
                  className={classNames(
                    classes.cell,
                    classes.priceCell,
                    hasPromoTariff && classes.promoPriceCell,
                    hideBbOutOffContract && classes.justifyContentCenter
                  )}
                >
                  <Typography
                    color="primary"
                    className={classNames(
                      classes.subtitle,
                      hasPromo && classes.promoStyles
                    )}
                  >
                    {formatPrice(getTariffPriceWithLineRental(tariff))}
                  </Typography>
                  <Typography variant="body2" component="span">
                    a month
                  </Typography>
                  {!hideBbOutOffContract && contract.promo && (
                    <Typography
                      className={classes.outOfContractPrice}
                      variant="caption"
                    >
                      for {contract.promo.length} months, then{' '}
                      <Typography
                        variant="body2"
                        component="span"
                        classes={{ root: classes.bold }}
                      >
                        {formatPrice(contract.monthlyPriceWithLineRental)}
                      </Typography>{' '}
                      for {contract.length - contract.promo.length} months
                    </Typography>
                  )}
                </div>
                <div
                  className={classNames(classes.cell, classes.buttonContainer)}
                >
                  <Button
                    variant={
                      selectedTariffId === meta.id ? 'contained' : 'outlined'
                    }
                    onClick={() => onSelectTariff(tariff)}
                    className={classes.button}
                    data-test-id={`bb_tariff_${meta.id}`}
                  >
                    {selectedTariffId === meta.id ? 'Selected' : 'Select'}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TariffTable.propTypes = {
  tariffs: PropTypes.arrayOf(
    PropTypes.shape({
      tariffLabel: PropTypes.string.isRequired,
      downloadSpeed: PropTypes.shape({
        atLeast: PropTypes.shape({
          speed: PropTypes.number.isRequired,
          unit: PropTypes.string.isRequired,
        }),
        min: PropTypes.shape({
          speed: PropTypes.number.isRequired,
          unit: PropTypes.string.isRequired,
        }),
        max: PropTypes.shape({
          speed: PropTypes.number.isRequired,
          unit: PropTypes.string.isRequired,
        }),
      }).isRequired,
      uploadSpeed: PropTypes.shape({
        min: PropTypes.shape({
          speed: PropTypes.number.isRequired,
          unit: PropTypes.string.isRequired,
        }),
        max: PropTypes.shape({
          speed: PropTypes.number.isRequired,
          unit: PropTypes.string.isRequired,
        }),
      }).isRequired,
      contract: PropTypes.shape({
        monthlyPriceWithLineRental: currency,
        promo: PropTypes.shape({
          monthlyPriceWithLineRental: currency,
          length: PropTypes.number,
        }),
      }).isRequired,
      meta: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  selectedTariffId: PropTypes.string,
  onSelectTariff: PropTypes.func.isRequired,
};

export default TariffTable;
